import { CONFIG } from './config'

export const WORDS = [
"अहम्",
"त्वम्",
"स",
"वयम्",
"नस्",
"यूयम्",
"वस्",
"ते",
"इदम्",
"तत्",
"अत्र",
"तत्र",
"क",
"किम्",
"कुत्र",
"कदा",
"कथम्",
"न",
"सर्व",
"बहु",
"किञ्चिद्",
"अल्प",
"अन्य",
"एक",
"द्वि",
"त्रि",
"चतुर्",
"पञ्चन्",
"महत्",
"दीर्घ",
"उरु",
"घन",
"गुरु",
"अल्प",
"ह्रस्व",
"अंहु",
"तनु",
"स्त्री",
"पुरुष",
"नर",
"मनुष्य",
"मानव",
"बाल",
"शिशु",
"पत्नी",
"भार्या",
"पति",
"मातृ",
"पितृ",
"पशु",
"मत्स्य",
"वि",
"पक्षिन्",
"श्वन्",
"यूका",
"सर्प",
"कृमि",
"वृक्ष",
"तरु",
"वन",
"दण्ड",
"फल",
"बीज",
"पत्त्र",
"मूल",
"त्वच्",
"पुष्प",
"तृण",
"रज्जु",
"चर्मन्",
"त्वच्",
"मांस",
"रक्त",
"असृज्",
"अस्थि",
"पीवस्",
"मेदस्",
"अण्ड",
"शृङ्ग",
"पुच्छ",
"पर्ण",
"केश",
"शिरस्",
"कर्ण",
"अक्षि",
"नासा",
"वक्त्र",
"मुख",
"दन्त",
"जिह्वा",
"नख",
"पद",
"जङ्घ",
"जानु",
"हस्त",
"पाणि",
"पक्ष",
"उदर",
"अन्त्र",
"आन्त्र",
"गुद",
"गल",
"ग्रीवा",
"पृष्ठ",
"स्तन",
"हृदय",
"यकृत्",
"पिबति",
"खादति",
"अत्ति",
"दशति",
"धयति",
"ष्ठीवति",
"वमति",
"वाति",
"अनिति",
"स्मयते",
"हसति",
"पश्यति",
"दृश्",
"शृणोति",
"जानाति",
"मन्यते",
"चिन्तयति",
"जिघ्रति",
"बिभेति",
"भयते",
"स्वपिति",
"जीवति",
"म्रियते",
"हन्ति",
"युध्यते",
"वेति",
"हन्ति",
"ताडयति",
"कृन्तति",
"भिनत्ति",
"विधति",
"लिखति",
"खनति",
"प्लवते",
"पतति",
"एति",
"गच्छति",
"चरति",
"आगच्छति",
"शेते",
"सीदति",
"तिष्ठति",
"वर्तते",
"पद्यते",
"ददाति",
"धरति",
"मृद्नाति",
"घर्षति",
"क्षालयति",
"मार्ष्टि",
"कर्षति",
"नुदति",
"क्षिपति",
"बध्नाति",
"बन्धति",
"सीव्यति",
"गणयति",
"कलते",
"वक्ति",
"गायति",
"दीव्यति",
"प्लवते",
"सरति",
"क्षरति",
"शीयते",
"श्वयति",
"सूर्य",
"रवि",
"सूर",
"भास्कर",
"मास",
"चन्द्रमस्",
"चन्द्र",
"नक्षत्र",
"स्तृ",
"तारा",
"जल",
"अप्",
"पानीय",
"वारि",
"उदन्",
"तोज",
"वर्ष",
"नदी",
"सरस्",
"समुद्र",
"लवण",
"अश्मन्",
"पांसु",
"शिकता",
"रेणु",
"क्षम्",
"पृथ्वी",
"नभस्",
"मेघ",
"मिह्",
"आकाश",
"वायु",
"वात",
"हिम",
"तुषार",
"तुहिन",
"हिम",
"धूम",
"अग्नि",
"आस",
"दहति",
"पथ",
"अध्वन्",
"मार्ग",
"गिरि",
"पर्वत",
"रक्त",
"रोहित",
"हरित्",
"रित",
"लाश",
"पलाश",
"पीत",
"पीतल",
"श्वेत",
"कृष्ण",
"रात्रि",
"नक्ति",
"क्षप्",
"रजनी",
"दिन",
"अहर्",
"दिवस",
"वर्ष",
"संवत्सर",
"तप्त",
"शीत",
"पूर्ण",
"नव",
"नूतन",
"जीर्ण",
"वृद्ध",
"पुरातन",
"वसु",
"भद्र",
"पाप",
"दुष्ट",
"पूति",
"मलिन",
"मल",
"ऋजु",
"साधु",
"वृत्त",
"वर्तुल",
"तीक्ष्ण",
"कुण्ठ",
"श्लक्ष्ण",
"स्निग्ध",
"आर्द्र",
"क्लिन्न",
"शुष्क",
"शुद्ध",
"सत्य",
"नेद",
"प्रति",
"दूर",
"दक्षिण",
"सव्य",
"सह",
"च",
"यदि",
"हि",
"नामन्",
]

if (CONFIG.normalization) {
  WORDS.forEach((val, i) => (WORDS[i] = val.normalize(CONFIG.normalization)))
}

function shuffle(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
}

if (CONFIG.shuffle) {
  shuffle(WORDS)
}
