import { CONFIG } from './config'

//Valid combinations of characters that form the language orthography
export const ORTHOGRAPHY = [
'कअ',
'कअऀ',
'कअँ',
'कअं',
'कअः',
'कअऺ',
'कअऻ',
'कअ़',
'कअा',
'कअि',
'कअी',
'कअु',
'कअू',
'कअृ',
'कअॄ',
'कअॅ',
'कअॆ',
'कअे',
'कअै',
'कअॉ',
'कअॊ',
'कअो',
'कअौ',
'कअ्',
'कअॎ',
'कअॏ',
'कअॢ',
'कअॣ',
'कइ',
'कइऀ',
'कइँ',
'कइं',
'कइः',
'कइऺ',
'कइऻ',
'कइ़',
'कइा',
'कइि',
'कइी',
'कइु',
'कइू',
'कइृ',
'कइॄ',
'कइॅ',
'कइॆ',
'कइे',
'कइै',
'कइॉ',
'कइॊ',
'कइो',
'कइौ',
'कइ्',
'कइॎ',
'कइॏ',
'कइॢ',
'कइॣ',
'कउ',
'कउऀ',
'कउँ',
'कउं',
'कउः',
'कउऺ',
'कउऻ',
'कउ़',
'कउा',
'कउि',
'कउी',
'कउु',
'कउू',
'कउृ',
'कउॄ',
'कउॅ',
'कउॆ',
'कउे',
'कउै',
'कउॉ',
'कउॊ',
'कउो',
'कउौ',
'कउ्',
'कउॎ',
'कउॏ',
'कउॢ',
'कउॣ',
'कऋ',
'कऋऀ',
'कऋँ',
'कऋं',
'कऋः',
'कऋऺ',
'कऋऻ',
'कऋ़',
'कऋा',
'कऋि',
'कऋी',
'कऋु',
'कऋू',
'कऋृ',
'कऋॄ',
'कऋॅ',
'कऋॆ',
'कऋे',
'कऋै',
'कऋॉ',
'कऋॊ',
'कऋो',
'कऋौ',
'कऋ्',
'कऋॎ',
'कऋॏ',
'कऋॢ',
'कऋॣ',
'कऌ',
'कऌऀ',
'कऌँ',
'कऌं',
'कऌः',
'कऌऺ',
'कऌऻ',
'कऌ़',
'कऌा',
'कऌि',
'कऌी',
'कऌु',
'कऌू',
'कऌृ',
'कऌॄ',
'कऌॅ',
'कऌॆ',
'कऌे',
'कऌै',
'कऌॉ',
'कऌॊ',
'कऌो',
'कऌौ',
'कऌ्',
'कऌॎ',
'कऌॏ',
'कऌॢ',
'कऌॣ',
'कए',
'कएऀ',
'कएँ',
'कएं',
'कएः',
'कएऺ',
'कएऻ',
'कए़',
'कएा',
'कएि',
'कएी',
'कएु',
'कएू',
'कएृ',
'कएॄ',
'कएॅ',
'कएॆ',
'कएे',
'कएै',
'कएॉ',
'कएॊ',
'कएो',
'कएौ',
'कए्',
'कएॎ',
'कएॏ',
'कएॢ',
'कएॣ',
'कऐ',
'कऐऀ',
'कऐँ',
'कऐं',
'कऐः',
'कऐऺ',
'कऐऻ',
'कऐ़',
'कऐा',
'कऐि',
'कऐी',
'कऐु',
'कऐू',
'कऐृ',
'कऐॄ',
'कऐॅ',
'कऐॆ',
'कऐे',
'कऐै',
'कऐॉ',
'कऐॊ',
'कऐो',
'कऐौ',
'कऐ्',
'कऐॎ',
'कऐॏ',
'कऐॢ',
'कऐॣ',
'कओ',
'कओऀ',
'कओँ',
'कओं',
'कओः',
'कओऺ',
'कओऻ',
'कओ़',
'कओा',
'कओि',
'कओी',
'कओु',
'कओू',
'कओृ',
'कओॄ',
'कओॅ',
'कओॆ',
'कओे',
'कओै',
'कओॉ',
'कओॊ',
'कओो',
'कओौ',
'कओ्',
'कओॎ',
'कओॏ',
'कओॢ',
'कओॣ',
'कऔ',
'कऔऀ',
'कऔँ',
'कऔं',
'कऔः',
'कऔऺ',
'कऔऻ',
'कऔ़',
'कऔा',
'कऔि',
'कऔी',
'कऔु',
'कऔू',
'कऔृ',
'कऔॄ',
'कऔॅ',
'कऔॆ',
'कऔे',
'कऔै',
'कऔॉ',
'कऔॊ',
'कऔो',
'कऔौ',
'कऔ्',
'कऔॎ',
'कऔॏ',
'कऔॢ',
'कऔॣ',
'खअ',
'खअऀ',
'खअँ',
'खअं',
'खअः',
'खअऺ',
'खअऻ',
'खअ़',
'खअा',
'खअि',
'खअी',
'खअु',
'खअू',
'खअृ',
'खअॄ',
'खअॅ',
'खअॆ',
'खअे',
'खअै',
'खअॉ',
'खअॊ',
'खअो',
'खअौ',
'खअ्',
'खअॎ',
'खअॏ',
'खअॢ',
'खअॣ',
'खइ',
'खइऀ',
'खइँ',
'खइं',
'खइः',
'खइऺ',
'खइऻ',
'खइ़',
'खइा',
'खइि',
'खइी',
'खइु',
'खइू',
'खइृ',
'खइॄ',
'खइॅ',
'खइॆ',
'खइे',
'खइै',
'खइॉ',
'खइॊ',
'खइो',
'खइौ',
'खइ्',
'खइॎ',
'खइॏ',
'खइॢ',
'खइॣ',
'खउ',
'खउऀ',
'खउँ',
'खउं',
'खउः',
'खउऺ',
'खउऻ',
'खउ़',
'खउा',
'खउि',
'खउी',
'खउु',
'खउू',
'खउृ',
'खउॄ',
'खउॅ',
'खउॆ',
'खउे',
'खउै',
'खउॉ',
'खउॊ',
'खउो',
'खउौ',
'खउ्',
'खउॎ',
'खउॏ',
'खउॢ',
'खउॣ',
'खऋ',
'खऋऀ',
'खऋँ',
'खऋं',
'खऋः',
'खऋऺ',
'खऋऻ',
'खऋ़',
'खऋा',
'खऋि',
'खऋी',
'खऋु',
'खऋू',
'खऋृ',
'खऋॄ',
'खऋॅ',
'खऋॆ',
'खऋे',
'खऋै',
'खऋॉ',
'खऋॊ',
'खऋो',
'खऋौ',
'खऋ्',
'खऋॎ',
'खऋॏ',
'खऋॢ',
'खऋॣ',
'खऌ',
'खऌऀ',
'खऌँ',
'खऌं',
'खऌः',
'खऌऺ',
'खऌऻ',
'खऌ़',
'खऌा',
'खऌि',
'खऌी',
'खऌु',
'खऌू',
'खऌृ',
'खऌॄ',
'खऌॅ',
'खऌॆ',
'खऌे',
'खऌै',
'खऌॉ',
'खऌॊ',
'खऌो',
'खऌौ',
'खऌ्',
'खऌॎ',
'खऌॏ',
'खऌॢ',
'खऌॣ',
'खए',
'खएऀ',
'खएँ',
'खएं',
'खएः',
'खएऺ',
'खएऻ',
'खए़',
'खएा',
'खएि',
'खएी',
'खएु',
'खएू',
'खएृ',
'खएॄ',
'खएॅ',
'खएॆ',
'खएे',
'खएै',
'खएॉ',
'खएॊ',
'खएो',
'खएौ',
'खए्',
'खएॎ',
'खएॏ',
'खएॢ',
'खएॣ',
'खऐ',
'खऐऀ',
'खऐँ',
'खऐं',
'खऐः',
'खऐऺ',
'खऐऻ',
'खऐ़',
'खऐा',
'खऐि',
'खऐी',
'खऐु',
'खऐू',
'खऐृ',
'खऐॄ',
'खऐॅ',
'खऐॆ',
'खऐे',
'खऐै',
'खऐॉ',
'खऐॊ',
'खऐो',
'खऐौ',
'खऐ्',
'खऐॎ',
'खऐॏ',
'खऐॢ',
'खऐॣ',
'खओ',
'खओऀ',
'खओँ',
'खओं',
'खओः',
'खओऺ',
'खओऻ',
'खओ़',
'खओा',
'खओि',
'खओी',
'खओु',
'खओू',
'खओृ',
'खओॄ',
'खओॅ',
'खओॆ',
'खओे',
'खओै',
'खओॉ',
'खओॊ',
'खओो',
'खओौ',
'खओ्',
'खओॎ',
'खओॏ',
'खओॢ',
'खओॣ',
'खऔ',
'खऔऀ',
'खऔँ',
'खऔं',
'खऔः',
'खऔऺ',
'खऔऻ',
'खऔ़',
'खऔा',
'खऔि',
'खऔी',
'खऔु',
'खऔू',
'खऔृ',
'खऔॄ',
'खऔॅ',
'खऔॆ',
'खऔे',
'खऔै',
'खऔॉ',
'खऔॊ',
'खऔो',
'खऔौ',
'खऔ्',
'खऔॎ',
'खऔॏ',
'खऔॢ',
'खऔॣ',
'गअ',
'गअऀ',
'गअँ',
'गअं',
'गअः',
'गअऺ',
'गअऻ',
'गअ़',
'गअा',
'गअि',
'गअी',
'गअु',
'गअू',
'गअृ',
'गअॄ',
'गअॅ',
'गअॆ',
'गअे',
'गअै',
'गअॉ',
'गअॊ',
'गअो',
'गअौ',
'गअ्',
'गअॎ',
'गअॏ',
'गअॢ',
'गअॣ',
'गइ',
'गइऀ',
'गइँ',
'गइं',
'गइः',
'गइऺ',
'गइऻ',
'गइ़',
'गइा',
'गइि',
'गइी',
'गइु',
'गइू',
'गइृ',
'गइॄ',
'गइॅ',
'गइॆ',
'गइे',
'गइै',
'गइॉ',
'गइॊ',
'गइो',
'गइौ',
'गइ्',
'गइॎ',
'गइॏ',
'गइॢ',
'गइॣ',
'गउ',
'गउऀ',
'गउँ',
'गउं',
'गउः',
'गउऺ',
'गउऻ',
'गउ़',
'गउा',
'गउि',
'गउी',
'गउु',
'गउू',
'गउृ',
'गउॄ',
'गउॅ',
'गउॆ',
'गउे',
'गउै',
'गउॉ',
'गउॊ',
'गउो',
'गउौ',
'गउ्',
'गउॎ',
'गउॏ',
'गउॢ',
'गउॣ',
'गऋ',
'गऋऀ',
'गऋँ',
'गऋं',
'गऋः',
'गऋऺ',
'गऋऻ',
'गऋ़',
'गऋा',
'गऋि',
'गऋी',
'गऋु',
'गऋू',
'गऋृ',
'गऋॄ',
'गऋॅ',
'गऋॆ',
'गऋे',
'गऋै',
'गऋॉ',
'गऋॊ',
'गऋो',
'गऋौ',
'गऋ्',
'गऋॎ',
'गऋॏ',
'गऋॢ',
'गऋॣ',
'गऌ',
'गऌऀ',
'गऌँ',
'गऌं',
'गऌः',
'गऌऺ',
'गऌऻ',
'गऌ़',
'गऌा',
'गऌि',
'गऌी',
'गऌु',
'गऌू',
'गऌृ',
'गऌॄ',
'गऌॅ',
'गऌॆ',
'गऌे',
'गऌै',
'गऌॉ',
'गऌॊ',
'गऌो',
'गऌौ',
'गऌ्',
'गऌॎ',
'गऌॏ',
'गऌॢ',
'गऌॣ',
'गए',
'गएऀ',
'गएँ',
'गएं',
'गएः',
'गएऺ',
'गएऻ',
'गए़',
'गएा',
'गएि',
'गएी',
'गएु',
'गएू',
'गएृ',
'गएॄ',
'गएॅ',
'गएॆ',
'गएे',
'गएै',
'गएॉ',
'गएॊ',
'गएो',
'गएौ',
'गए्',
'गएॎ',
'गएॏ',
'गएॢ',
'गएॣ',
'गऐ',
'गऐऀ',
'गऐँ',
'गऐं',
'गऐः',
'गऐऺ',
'गऐऻ',
'गऐ़',
'गऐा',
'गऐि',
'गऐी',
'गऐु',
'गऐू',
'गऐृ',
'गऐॄ',
'गऐॅ',
'गऐॆ',
'गऐे',
'गऐै',
'गऐॉ',
'गऐॊ',
'गऐो',
'गऐौ',
'गऐ्',
'गऐॎ',
'गऐॏ',
'गऐॢ',
'गऐॣ',
'गओ',
'गओऀ',
'गओँ',
'गओं',
'गओः',
'गओऺ',
'गओऻ',
'गओ़',
'गओा',
'गओि',
'गओी',
'गओु',
'गओू',
'गओृ',
'गओॄ',
'गओॅ',
'गओॆ',
'गओे',
'गओै',
'गओॉ',
'गओॊ',
'गओो',
'गओौ',
'गओ्',
'गओॎ',
'गओॏ',
'गओॢ',
'गओॣ',
'गऔ',
'गऔऀ',
'गऔँ',
'गऔं',
'गऔः',
'गऔऺ',
'गऔऻ',
'गऔ़',
'गऔा',
'गऔि',
'गऔी',
'गऔु',
'गऔू',
'गऔृ',
'गऔॄ',
'गऔॅ',
'गऔॆ',
'गऔे',
'गऔै',
'गऔॉ',
'गऔॊ',
'गऔो',
'गऔौ',
'गऔ्',
'गऔॎ',
'गऔॏ',
'गऔॢ',
'गऔॣ',
'घअ',
'घअऀ',
'घअँ',
'घअं',
'घअः',
'घअऺ',
'घअऻ',
'घअ़',
'घअा',
'घअि',
'घअी',
'घअु',
'घअू',
'घअृ',
'घअॄ',
'घअॅ',
'घअॆ',
'घअे',
'घअै',
'घअॉ',
'घअॊ',
'घअो',
'घअौ',
'घअ्',
'घअॎ',
'घअॏ',
'घअॢ',
'घअॣ',
'घइ',
'घइऀ',
'घइँ',
'घइं',
'घइः',
'घइऺ',
'घइऻ',
'घइ़',
'घइा',
'घइि',
'घइी',
'घइु',
'घइू',
'घइृ',
'घइॄ',
'घइॅ',
'घइॆ',
'घइे',
'घइै',
'घइॉ',
'घइॊ',
'घइो',
'घइौ',
'घइ्',
'घइॎ',
'घइॏ',
'घइॢ',
'घइॣ',
'घउ',
'घउऀ',
'घउँ',
'घउं',
'घउः',
'घउऺ',
'घउऻ',
'घउ़',
'घउा',
'घउि',
'घउी',
'घउु',
'घउू',
'घउृ',
'घउॄ',
'घउॅ',
'घउॆ',
'घउे',
'घउै',
'घउॉ',
'घउॊ',
'घउो',
'घउौ',
'घउ्',
'घउॎ',
'घउॏ',
'घउॢ',
'घउॣ',
'घऋ',
'घऋऀ',
'घऋँ',
'घऋं',
'घऋः',
'घऋऺ',
'घऋऻ',
'घऋ़',
'घऋा',
'घऋि',
'घऋी',
'घऋु',
'घऋू',
'घऋृ',
'घऋॄ',
'घऋॅ',
'घऋॆ',
'घऋे',
'घऋै',
'घऋॉ',
'घऋॊ',
'घऋो',
'घऋौ',
'घऋ्',
'घऋॎ',
'घऋॏ',
'घऋॢ',
'घऋॣ',
'घऌ',
'घऌऀ',
'घऌँ',
'घऌं',
'घऌः',
'घऌऺ',
'घऌऻ',
'घऌ़',
'घऌा',
'घऌि',
'घऌी',
'घऌु',
'घऌू',
'घऌृ',
'घऌॄ',
'घऌॅ',
'घऌॆ',
'घऌे',
'घऌै',
'घऌॉ',
'घऌॊ',
'घऌो',
'घऌौ',
'घऌ्',
'घऌॎ',
'घऌॏ',
'घऌॢ',
'घऌॣ',
'घए',
'घएऀ',
'घएँ',
'घएं',
'घएः',
'घएऺ',
'घएऻ',
'घए़',
'घएा',
'घएि',
'घएी',
'घएु',
'घएू',
'घएृ',
'घएॄ',
'घएॅ',
'घएॆ',
'घएे',
'घएै',
'घएॉ',
'घएॊ',
'घएो',
'घएौ',
'घए्',
'घएॎ',
'घएॏ',
'घएॢ',
'घएॣ',
'घऐ',
'घऐऀ',
'घऐँ',
'घऐं',
'घऐः',
'घऐऺ',
'घऐऻ',
'घऐ़',
'घऐा',
'घऐि',
'घऐी',
'घऐु',
'घऐू',
'घऐृ',
'घऐॄ',
'घऐॅ',
'घऐॆ',
'घऐे',
'घऐै',
'घऐॉ',
'घऐॊ',
'घऐो',
'घऐौ',
'घऐ्',
'घऐॎ',
'घऐॏ',
'घऐॢ',
'घऐॣ',
'घओ',
'घओऀ',
'घओँ',
'घओं',
'घओः',
'घओऺ',
'घओऻ',
'घओ़',
'घओा',
'घओि',
'घओी',
'घओु',
'घओू',
'घओृ',
'घओॄ',
'घओॅ',
'घओॆ',
'घओे',
'घओै',
'घओॉ',
'घओॊ',
'घओो',
'घओौ',
'घओ्',
'घओॎ',
'घओॏ',
'घओॢ',
'घओॣ',
'घऔ',
'घऔऀ',
'घऔँ',
'घऔं',
'घऔः',
'घऔऺ',
'घऔऻ',
'घऔ़',
'घऔा',
'घऔि',
'घऔी',
'घऔु',
'घऔू',
'घऔृ',
'घऔॄ',
'घऔॅ',
'घऔॆ',
'घऔे',
'घऔै',
'घऔॉ',
'घऔॊ',
'घऔो',
'घऔौ',
'घऔ्',
'घऔॎ',
'घऔॏ',
'घऔॢ',
'घऔॣ',
'ङअ',
'ङअऀ',
'ङअँ',
'ङअं',
'ङअः',
'ङअऺ',
'ङअऻ',
'ङअ़',
'ङअा',
'ङअि',
'ङअी',
'ङअु',
'ङअू',
'ङअृ',
'ङअॄ',
'ङअॅ',
'ङअॆ',
'ङअे',
'ङअै',
'ङअॉ',
'ङअॊ',
'ङअो',
'ङअौ',
'ङअ्',
'ङअॎ',
'ङअॏ',
'ङअॢ',
'ङअॣ',
'ङइ',
'ङइऀ',
'ङइँ',
'ङइं',
'ङइः',
'ङइऺ',
'ङइऻ',
'ङइ़',
'ङइा',
'ङइि',
'ङइी',
'ङइु',
'ङइू',
'ङइृ',
'ङइॄ',
'ङइॅ',
'ङइॆ',
'ङइे',
'ङइै',
'ङइॉ',
'ङइॊ',
'ङइो',
'ङइौ',
'ङइ्',
'ङइॎ',
'ङइॏ',
'ङइॢ',
'ङइॣ',
'ङउ',
'ङउऀ',
'ङउँ',
'ङउं',
'ङउः',
'ङउऺ',
'ङउऻ',
'ङउ़',
'ङउा',
'ङउि',
'ङउी',
'ङउु',
'ङउू',
'ङउृ',
'ङउॄ',
'ङउॅ',
'ङउॆ',
'ङउे',
'ङउै',
'ङउॉ',
'ङउॊ',
'ङउो',
'ङउौ',
'ङउ्',
'ङउॎ',
'ङउॏ',
'ङउॢ',
'ङउॣ',
'ङऋ',
'ङऋऀ',
'ङऋँ',
'ङऋं',
'ङऋः',
'ङऋऺ',
'ङऋऻ',
'ङऋ़',
'ङऋा',
'ङऋि',
'ङऋी',
'ङऋु',
'ङऋू',
'ङऋृ',
'ङऋॄ',
'ङऋॅ',
'ङऋॆ',
'ङऋे',
'ङऋै',
'ङऋॉ',
'ङऋॊ',
'ङऋो',
'ङऋौ',
'ङऋ्',
'ङऋॎ',
'ङऋॏ',
'ङऋॢ',
'ङऋॣ',
'ङऌ',
'ङऌऀ',
'ङऌँ',
'ङऌं',
'ङऌः',
'ङऌऺ',
'ङऌऻ',
'ङऌ़',
'ङऌा',
'ङऌि',
'ङऌी',
'ङऌु',
'ङऌू',
'ङऌृ',
'ङऌॄ',
'ङऌॅ',
'ङऌॆ',
'ङऌे',
'ङऌै',
'ङऌॉ',
'ङऌॊ',
'ङऌो',
'ङऌौ',
'ङऌ्',
'ङऌॎ',
'ङऌॏ',
'ङऌॢ',
'ङऌॣ',
'ङए',
'ङएऀ',
'ङएँ',
'ङएं',
'ङएः',
'ङएऺ',
'ङएऻ',
'ङए़',
'ङएा',
'ङएि',
'ङएी',
'ङएु',
'ङएू',
'ङएृ',
'ङएॄ',
'ङएॅ',
'ङएॆ',
'ङएे',
'ङएै',
'ङएॉ',
'ङएॊ',
'ङएो',
'ङएौ',
'ङए्',
'ङएॎ',
'ङएॏ',
'ङएॢ',
'ङएॣ',
'ङऐ',
'ङऐऀ',
'ङऐँ',
'ङऐं',
'ङऐः',
'ङऐऺ',
'ङऐऻ',
'ङऐ़',
'ङऐा',
'ङऐि',
'ङऐी',
'ङऐु',
'ङऐू',
'ङऐृ',
'ङऐॄ',
'ङऐॅ',
'ङऐॆ',
'ङऐे',
'ङऐै',
'ङऐॉ',
'ङऐॊ',
'ङऐो',
'ङऐौ',
'ङऐ्',
'ङऐॎ',
'ङऐॏ',
'ङऐॢ',
'ङऐॣ',
'ङओ',
'ङओऀ',
'ङओँ',
'ङओं',
'ङओः',
'ङओऺ',
'ङओऻ',
'ङओ़',
'ङओा',
'ङओि',
'ङओी',
'ङओु',
'ङओू',
'ङओृ',
'ङओॄ',
'ङओॅ',
'ङओॆ',
'ङओे',
'ङओै',
'ङओॉ',
'ङओॊ',
'ङओो',
'ङओौ',
'ङओ्',
'ङओॎ',
'ङओॏ',
'ङओॢ',
'ङओॣ',
'ङऔ',
'ङऔऀ',
'ङऔँ',
'ङऔं',
'ङऔः',
'ङऔऺ',
'ङऔऻ',
'ङऔ़',
'ङऔा',
'ङऔि',
'ङऔी',
'ङऔु',
'ङऔू',
'ङऔृ',
'ङऔॄ',
'ङऔॅ',
'ङऔॆ',
'ङऔे',
'ङऔै',
'ङऔॉ',
'ङऔॊ',
'ङऔो',
'ङऔौ',
'ङऔ्',
'ङऔॎ',
'ङऔॏ',
'ङऔॢ',
'ङऔॣ',
'चअ',
'चअऀ',
'चअँ',
'चअं',
'चअः',
'चअऺ',
'चअऻ',
'चअ़',
'चअा',
'चअि',
'चअी',
'चअु',
'चअू',
'चअृ',
'चअॄ',
'चअॅ',
'चअॆ',
'चअे',
'चअै',
'चअॉ',
'चअॊ',
'चअो',
'चअौ',
'चअ्',
'चअॎ',
'चअॏ',
'चअॢ',
'चअॣ',
'चइ',
'चइऀ',
'चइँ',
'चइं',
'चइः',
'चइऺ',
'चइऻ',
'चइ़',
'चइा',
'चइि',
'चइी',
'चइु',
'चइू',
'चइृ',
'चइॄ',
'चइॅ',
'चइॆ',
'चइे',
'चइै',
'चइॉ',
'चइॊ',
'चइो',
'चइौ',
'चइ्',
'चइॎ',
'चइॏ',
'चइॢ',
'चइॣ',
'चउ',
'चउऀ',
'चउँ',
'चउं',
'चउः',
'चउऺ',
'चउऻ',
'चउ़',
'चउा',
'चउि',
'चउी',
'चउु',
'चउू',
'चउृ',
'चउॄ',
'चउॅ',
'चउॆ',
'चउे',
'चउै',
'चउॉ',
'चउॊ',
'चउो',
'चउौ',
'चउ्',
'चउॎ',
'चउॏ',
'चउॢ',
'चउॣ',
'चऋ',
'चऋऀ',
'चऋँ',
'चऋं',
'चऋः',
'चऋऺ',
'चऋऻ',
'चऋ़',
'चऋा',
'चऋि',
'चऋी',
'चऋु',
'चऋू',
'चऋृ',
'चऋॄ',
'चऋॅ',
'चऋॆ',
'चऋे',
'चऋै',
'चऋॉ',
'चऋॊ',
'चऋो',
'चऋौ',
'चऋ्',
'चऋॎ',
'चऋॏ',
'चऋॢ',
'चऋॣ',
'चऌ',
'चऌऀ',
'चऌँ',
'चऌं',
'चऌः',
'चऌऺ',
'चऌऻ',
'चऌ़',
'चऌा',
'चऌि',
'चऌी',
'चऌु',
'चऌू',
'चऌृ',
'चऌॄ',
'चऌॅ',
'चऌॆ',
'चऌे',
'चऌै',
'चऌॉ',
'चऌॊ',
'चऌो',
'चऌौ',
'चऌ्',
'चऌॎ',
'चऌॏ',
'चऌॢ',
'चऌॣ',
'चए',
'चएऀ',
'चएँ',
'चएं',
'चएः',
'चएऺ',
'चएऻ',
'चए़',
'चएा',
'चएि',
'चएी',
'चएु',
'चएू',
'चएृ',
'चएॄ',
'चएॅ',
'चएॆ',
'चएे',
'चएै',
'चएॉ',
'चएॊ',
'चएो',
'चएौ',
'चए्',
'चएॎ',
'चएॏ',
'चएॢ',
'चएॣ',
'चऐ',
'चऐऀ',
'चऐँ',
'चऐं',
'चऐः',
'चऐऺ',
'चऐऻ',
'चऐ़',
'चऐा',
'चऐि',
'चऐी',
'चऐु',
'चऐू',
'चऐृ',
'चऐॄ',
'चऐॅ',
'चऐॆ',
'चऐे',
'चऐै',
'चऐॉ',
'चऐॊ',
'चऐो',
'चऐौ',
'चऐ्',
'चऐॎ',
'चऐॏ',
'चऐॢ',
'चऐॣ',
'चओ',
'चओऀ',
'चओँ',
'चओं',
'चओः',
'चओऺ',
'चओऻ',
'चओ़',
'चओा',
'चओि',
'चओी',
'चओु',
'चओू',
'चओृ',
'चओॄ',
'चओॅ',
'चओॆ',
'चओे',
'चओै',
'चओॉ',
'चओॊ',
'चओो',
'चओौ',
'चओ्',
'चओॎ',
'चओॏ',
'चओॢ',
'चओॣ',
'चऔ',
'चऔऀ',
'चऔँ',
'चऔं',
'चऔः',
'चऔऺ',
'चऔऻ',
'चऔ़',
'चऔा',
'चऔि',
'चऔी',
'चऔु',
'चऔू',
'चऔृ',
'चऔॄ',
'चऔॅ',
'चऔॆ',
'चऔे',
'चऔै',
'चऔॉ',
'चऔॊ',
'चऔो',
'चऔौ',
'चऔ्',
'चऔॎ',
'चऔॏ',
'चऔॢ',
'चऔॣ',
'छअ',
'छअऀ',
'छअँ',
'छअं',
'छअः',
'छअऺ',
'छअऻ',
'छअ़',
'छअा',
'छअि',
'छअी',
'छअु',
'छअू',
'छअृ',
'छअॄ',
'छअॅ',
'छअॆ',
'छअे',
'छअै',
'छअॉ',
'छअॊ',
'छअो',
'छअौ',
'छअ्',
'छअॎ',
'छअॏ',
'छअॢ',
'छअॣ',
'छइ',
'छइऀ',
'छइँ',
'छइं',
'छइः',
'छइऺ',
'छइऻ',
'छइ़',
'छइा',
'छइि',
'छइी',
'छइु',
'छइू',
'छइृ',
'छइॄ',
'छइॅ',
'छइॆ',
'छइे',
'छइै',
'छइॉ',
'छइॊ',
'छइो',
'छइौ',
'छइ्',
'छइॎ',
'छइॏ',
'छइॢ',
'छइॣ',
'छउ',
'छउऀ',
'छउँ',
'छउं',
'छउः',
'छउऺ',
'छउऻ',
'छउ़',
'छउा',
'छउि',
'छउी',
'छउु',
'छउू',
'छउृ',
'छउॄ',
'छउॅ',
'छउॆ',
'छउे',
'छउै',
'छउॉ',
'छउॊ',
'छउो',
'छउौ',
'छउ्',
'छउॎ',
'छउॏ',
'छउॢ',
'छउॣ',
'छऋ',
'छऋऀ',
'छऋँ',
'छऋं',
'छऋः',
'छऋऺ',
'छऋऻ',
'छऋ़',
'छऋा',
'छऋि',
'छऋी',
'छऋु',
'छऋू',
'छऋृ',
'छऋॄ',
'छऋॅ',
'छऋॆ',
'छऋे',
'छऋै',
'छऋॉ',
'छऋॊ',
'छऋो',
'छऋौ',
'छऋ्',
'छऋॎ',
'छऋॏ',
'छऋॢ',
'छऋॣ',
'छऌ',
'छऌऀ',
'छऌँ',
'छऌं',
'छऌः',
'छऌऺ',
'छऌऻ',
'छऌ़',
'छऌा',
'छऌि',
'छऌी',
'छऌु',
'छऌू',
'छऌृ',
'छऌॄ',
'छऌॅ',
'छऌॆ',
'छऌे',
'छऌै',
'छऌॉ',
'छऌॊ',
'छऌो',
'छऌौ',
'छऌ्',
'छऌॎ',
'छऌॏ',
'छऌॢ',
'छऌॣ',
'छए',
'छएऀ',
'छएँ',
'छएं',
'छएः',
'छएऺ',
'छएऻ',
'छए़',
'छएा',
'छएि',
'छएी',
'छएु',
'छएू',
'छएृ',
'छएॄ',
'छएॅ',
'छएॆ',
'छएे',
'छएै',
'छएॉ',
'छएॊ',
'छएो',
'छएौ',
'छए्',
'छएॎ',
'छएॏ',
'छएॢ',
'छएॣ',
'छऐ',
'छऐऀ',
'छऐँ',
'छऐं',
'छऐः',
'छऐऺ',
'छऐऻ',
'छऐ़',
'छऐा',
'छऐि',
'छऐी',
'छऐु',
'छऐू',
'छऐृ',
'छऐॄ',
'छऐॅ',
'छऐॆ',
'छऐे',
'छऐै',
'छऐॉ',
'छऐॊ',
'छऐो',
'छऐौ',
'छऐ्',
'छऐॎ',
'छऐॏ',
'छऐॢ',
'छऐॣ',
'छओ',
'छओऀ',
'छओँ',
'छओं',
'छओः',
'छओऺ',
'छओऻ',
'छओ़',
'छओा',
'छओि',
'छओी',
'छओु',
'छओू',
'छओृ',
'छओॄ',
'छओॅ',
'छओॆ',
'छओे',
'छओै',
'छओॉ',
'छओॊ',
'छओो',
'छओौ',
'छओ्',
'छओॎ',
'छओॏ',
'छओॢ',
'छओॣ',
'छऔ',
'छऔऀ',
'छऔँ',
'छऔं',
'छऔः',
'छऔऺ',
'छऔऻ',
'छऔ़',
'छऔा',
'छऔि',
'छऔी',
'छऔु',
'छऔू',
'छऔृ',
'छऔॄ',
'छऔॅ',
'छऔॆ',
'छऔे',
'छऔै',
'छऔॉ',
'छऔॊ',
'छऔो',
'छऔौ',
'छऔ्',
'छऔॎ',
'छऔॏ',
'छऔॢ',
'छऔॣ',
'जअ',
'जअऀ',
'जअँ',
'जअं',
'जअः',
'जअऺ',
'जअऻ',
'जअ़',
'जअा',
'जअि',
'जअी',
'जअु',
'जअू',
'जअृ',
'जअॄ',
'जअॅ',
'जअॆ',
'जअे',
'जअै',
'जअॉ',
'जअॊ',
'जअो',
'जअौ',
'जअ्',
'जअॎ',
'जअॏ',
'जअॢ',
'जअॣ',
'जइ',
'जइऀ',
'जइँ',
'जइं',
'जइः',
'जइऺ',
'जइऻ',
'जइ़',
'जइा',
'जइि',
'जइी',
'जइु',
'जइू',
'जइृ',
'जइॄ',
'जइॅ',
'जइॆ',
'जइे',
'जइै',
'जइॉ',
'जइॊ',
'जइो',
'जइौ',
'जइ्',
'जइॎ',
'जइॏ',
'जइॢ',
'जइॣ',
'जउ',
'जउऀ',
'जउँ',
'जउं',
'जउः',
'जउऺ',
'जउऻ',
'जउ़',
'जउा',
'जउि',
'जउी',
'जउु',
'जउू',
'जउृ',
'जउॄ',
'जउॅ',
'जउॆ',
'जउे',
'जउै',
'जउॉ',
'जउॊ',
'जउो',
'जउौ',
'जउ्',
'जउॎ',
'जउॏ',
'जउॢ',
'जउॣ',
'जऋ',
'जऋऀ',
'जऋँ',
'जऋं',
'जऋः',
'जऋऺ',
'जऋऻ',
'जऋ़',
'जऋा',
'जऋि',
'जऋी',
'जऋु',
'जऋू',
'जऋृ',
'जऋॄ',
'जऋॅ',
'जऋॆ',
'जऋे',
'जऋै',
'जऋॉ',
'जऋॊ',
'जऋो',
'जऋौ',
'जऋ्',
'जऋॎ',
'जऋॏ',
'जऋॢ',
'जऋॣ',
'जऌ',
'जऌऀ',
'जऌँ',
'जऌं',
'जऌः',
'जऌऺ',
'जऌऻ',
'जऌ़',
'जऌा',
'जऌि',
'जऌी',
'जऌु',
'जऌू',
'जऌृ',
'जऌॄ',
'जऌॅ',
'जऌॆ',
'जऌे',
'जऌै',
'जऌॉ',
'जऌॊ',
'जऌो',
'जऌौ',
'जऌ्',
'जऌॎ',
'जऌॏ',
'जऌॢ',
'जऌॣ',
'जए',
'जएऀ',
'जएँ',
'जएं',
'जएः',
'जएऺ',
'जएऻ',
'जए़',
'जएा',
'जएि',
'जएी',
'जएु',
'जएू',
'जएृ',
'जएॄ',
'जएॅ',
'जएॆ',
'जएे',
'जएै',
'जएॉ',
'जएॊ',
'जएो',
'जएौ',
'जए्',
'जएॎ',
'जएॏ',
'जएॢ',
'जएॣ',
'जऐ',
'जऐऀ',
'जऐँ',
'जऐं',
'जऐः',
'जऐऺ',
'जऐऻ',
'जऐ़',
'जऐा',
'जऐि',
'जऐी',
'जऐु',
'जऐू',
'जऐृ',
'जऐॄ',
'जऐॅ',
'जऐॆ',
'जऐे',
'जऐै',
'जऐॉ',
'जऐॊ',
'जऐो',
'जऐौ',
'जऐ्',
'जऐॎ',
'जऐॏ',
'जऐॢ',
'जऐॣ',
'जओ',
'जओऀ',
'जओँ',
'जओं',
'जओः',
'जओऺ',
'जओऻ',
'जओ़',
'जओा',
'जओि',
'जओी',
'जओु',
'जओू',
'जओृ',
'जओॄ',
'जओॅ',
'जओॆ',
'जओे',
'जओै',
'जओॉ',
'जओॊ',
'जओो',
'जओौ',
'जओ्',
'जओॎ',
'जओॏ',
'जओॢ',
'जओॣ',
'जऔ',
'जऔऀ',
'जऔँ',
'जऔं',
'जऔः',
'जऔऺ',
'जऔऻ',
'जऔ़',
'जऔा',
'जऔि',
'जऔी',
'जऔु',
'जऔू',
'जऔृ',
'जऔॄ',
'जऔॅ',
'जऔॆ',
'जऔे',
'जऔै',
'जऔॉ',
'जऔॊ',
'जऔो',
'जऔौ',
'जऔ्',
'जऔॎ',
'जऔॏ',
'जऔॢ',
'जऔॣ',
'झअ',
'झअऀ',
'झअँ',
'झअं',
'झअः',
'झअऺ',
'झअऻ',
'झअ़',
'झअा',
'झअि',
'झअी',
'झअु',
'झअू',
'झअृ',
'झअॄ',
'झअॅ',
'झअॆ',
'झअे',
'झअै',
'झअॉ',
'झअॊ',
'झअो',
'झअौ',
'झअ्',
'झअॎ',
'झअॏ',
'झअॢ',
'झअॣ',
'झइ',
'झइऀ',
'झइँ',
'झइं',
'झइः',
'झइऺ',
'झइऻ',
'झइ़',
'झइा',
'झइि',
'झइी',
'झइु',
'झइू',
'झइृ',
'झइॄ',
'झइॅ',
'झइॆ',
'झइे',
'झइै',
'झइॉ',
'झइॊ',
'झइो',
'झइौ',
'झइ्',
'झइॎ',
'झइॏ',
'झइॢ',
'झइॣ',
'झउ',
'झउऀ',
'झउँ',
'झउं',
'झउः',
'झउऺ',
'झउऻ',
'झउ़',
'झउा',
'झउि',
'झउी',
'झउु',
'झउू',
'झउृ',
'झउॄ',
'झउॅ',
'झउॆ',
'झउे',
'झउै',
'झउॉ',
'झउॊ',
'झउो',
'झउौ',
'झउ्',
'झउॎ',
'झउॏ',
'झउॢ',
'झउॣ',
'झऋ',
'झऋऀ',
'झऋँ',
'झऋं',
'झऋः',
'झऋऺ',
'झऋऻ',
'झऋ़',
'झऋा',
'झऋि',
'झऋी',
'झऋु',
'झऋू',
'झऋृ',
'झऋॄ',
'झऋॅ',
'झऋॆ',
'झऋे',
'झऋै',
'झऋॉ',
'झऋॊ',
'झऋो',
'झऋौ',
'झऋ्',
'झऋॎ',
'झऋॏ',
'झऋॢ',
'झऋॣ',
'झऌ',
'झऌऀ',
'झऌँ',
'झऌं',
'झऌः',
'झऌऺ',
'झऌऻ',
'झऌ़',
'झऌा',
'झऌि',
'झऌी',
'झऌु',
'झऌू',
'झऌृ',
'झऌॄ',
'झऌॅ',
'झऌॆ',
'झऌे',
'झऌै',
'झऌॉ',
'झऌॊ',
'झऌो',
'झऌौ',
'झऌ्',
'झऌॎ',
'झऌॏ',
'झऌॢ',
'झऌॣ',
'झए',
'झएऀ',
'झएँ',
'झएं',
'झएः',
'झएऺ',
'झएऻ',
'झए़',
'झएा',
'झएि',
'झएी',
'झएु',
'झएू',
'झएृ',
'झएॄ',
'झएॅ',
'झएॆ',
'झएे',
'झएै',
'झएॉ',
'झएॊ',
'झएो',
'झएौ',
'झए्',
'झएॎ',
'झएॏ',
'झएॢ',
'झएॣ',
'झऐ',
'झऐऀ',
'झऐँ',
'झऐं',
'झऐः',
'झऐऺ',
'झऐऻ',
'झऐ़',
'झऐा',
'झऐि',
'झऐी',
'झऐु',
'झऐू',
'झऐृ',
'झऐॄ',
'झऐॅ',
'झऐॆ',
'झऐे',
'झऐै',
'झऐॉ',
'झऐॊ',
'झऐो',
'झऐौ',
'झऐ्',
'झऐॎ',
'झऐॏ',
'झऐॢ',
'झऐॣ',
'झओ',
'झओऀ',
'झओँ',
'झओं',
'झओः',
'झओऺ',
'झओऻ',
'झओ़',
'झओा',
'झओि',
'झओी',
'झओु',
'झओू',
'झओृ',
'झओॄ',
'झओॅ',
'झओॆ',
'झओे',
'झओै',
'झओॉ',
'झओॊ',
'झओो',
'झओौ',
'झओ्',
'झओॎ',
'झओॏ',
'झओॢ',
'झओॣ',
'झऔ',
'झऔऀ',
'झऔँ',
'झऔं',
'झऔः',
'झऔऺ',
'झऔऻ',
'झऔ़',
'झऔा',
'झऔि',
'झऔी',
'झऔु',
'झऔू',
'झऔृ',
'झऔॄ',
'झऔॅ',
'झऔॆ',
'झऔे',
'झऔै',
'झऔॉ',
'झऔॊ',
'झऔो',
'झऔौ',
'झऔ्',
'झऔॎ',
'झऔॏ',
'झऔॢ',
'झऔॣ',
'ञअ',
'ञअऀ',
'ञअँ',
'ञअं',
'ञअः',
'ञअऺ',
'ञअऻ',
'ञअ़',
'ञअा',
'ञअि',
'ञअी',
'ञअु',
'ञअू',
'ञअृ',
'ञअॄ',
'ञअॅ',
'ञअॆ',
'ञअे',
'ञअै',
'ञअॉ',
'ञअॊ',
'ञअो',
'ञअौ',
'ञअ्',
'ञअॎ',
'ञअॏ',
'ञअॢ',
'ञअॣ',
'ञइ',
'ञइऀ',
'ञइँ',
'ञइं',
'ञइः',
'ञइऺ',
'ञइऻ',
'ञइ़',
'ञइा',
'ञइि',
'ञइी',
'ञइु',
'ञइू',
'ञइृ',
'ञइॄ',
'ञइॅ',
'ञइॆ',
'ञइे',
'ञइै',
'ञइॉ',
'ञइॊ',
'ञइो',
'ञइौ',
'ञइ्',
'ञइॎ',
'ञइॏ',
'ञइॢ',
'ञइॣ',
'ञउ',
'ञउऀ',
'ञउँ',
'ञउं',
'ञउः',
'ञउऺ',
'ञउऻ',
'ञउ़',
'ञउा',
'ञउि',
'ञउी',
'ञउु',
'ञउू',
'ञउृ',
'ञउॄ',
'ञउॅ',
'ञउॆ',
'ञउे',
'ञउै',
'ञउॉ',
'ञउॊ',
'ञउो',
'ञउौ',
'ञउ्',
'ञउॎ',
'ञउॏ',
'ञउॢ',
'ञउॣ',
'ञऋ',
'ञऋऀ',
'ञऋँ',
'ञऋं',
'ञऋः',
'ञऋऺ',
'ञऋऻ',
'ञऋ़',
'ञऋा',
'ञऋि',
'ञऋी',
'ञऋु',
'ञऋू',
'ञऋृ',
'ञऋॄ',
'ञऋॅ',
'ञऋॆ',
'ञऋे',
'ञऋै',
'ञऋॉ',
'ञऋॊ',
'ञऋो',
'ञऋौ',
'ञऋ्',
'ञऋॎ',
'ञऋॏ',
'ञऋॢ',
'ञऋॣ',
'ञऌ',
'ञऌऀ',
'ञऌँ',
'ञऌं',
'ञऌः',
'ञऌऺ',
'ञऌऻ',
'ञऌ़',
'ञऌा',
'ञऌि',
'ञऌी',
'ञऌु',
'ञऌू',
'ञऌृ',
'ञऌॄ',
'ञऌॅ',
'ञऌॆ',
'ञऌे',
'ञऌै',
'ञऌॉ',
'ञऌॊ',
'ञऌो',
'ञऌौ',
'ञऌ्',
'ञऌॎ',
'ञऌॏ',
'ञऌॢ',
'ञऌॣ',
'ञए',
'ञएऀ',
'ञएँ',
'ञएं',
'ञएः',
'ञएऺ',
'ञएऻ',
'ञए़',
'ञएा',
'ञएि',
'ञएी',
'ञएु',
'ञएू',
'ञएृ',
'ञएॄ',
'ञएॅ',
'ञएॆ',
'ञएे',
'ञएै',
'ञएॉ',
'ञएॊ',
'ञएो',
'ञएौ',
'ञए्',
'ञएॎ',
'ञएॏ',
'ञएॢ',
'ञएॣ',
'ञऐ',
'ञऐऀ',
'ञऐँ',
'ञऐं',
'ञऐः',
'ञऐऺ',
'ञऐऻ',
'ञऐ़',
'ञऐा',
'ञऐि',
'ञऐी',
'ञऐु',
'ञऐू',
'ञऐृ',
'ञऐॄ',
'ञऐॅ',
'ञऐॆ',
'ञऐे',
'ञऐै',
'ञऐॉ',
'ञऐॊ',
'ञऐो',
'ञऐौ',
'ञऐ्',
'ञऐॎ',
'ञऐॏ',
'ञऐॢ',
'ञऐॣ',
'ञओ',
'ञओऀ',
'ञओँ',
'ञओं',
'ञओः',
'ञओऺ',
'ञओऻ',
'ञओ़',
'ञओा',
'ञओि',
'ञओी',
'ञओु',
'ञओू',
'ञओृ',
'ञओॄ',
'ञओॅ',
'ञओॆ',
'ञओे',
'ञओै',
'ञओॉ',
'ञओॊ',
'ञओो',
'ञओौ',
'ञओ्',
'ञओॎ',
'ञओॏ',
'ञओॢ',
'ञओॣ',
'ञऔ',
'ञऔऀ',
'ञऔँ',
'ञऔं',
'ञऔः',
'ञऔऺ',
'ञऔऻ',
'ञऔ़',
'ञऔा',
'ञऔि',
'ञऔी',
'ञऔु',
'ञऔू',
'ञऔृ',
'ञऔॄ',
'ञऔॅ',
'ञऔॆ',
'ञऔे',
'ञऔै',
'ञऔॉ',
'ञऔॊ',
'ञऔो',
'ञऔौ',
'ञऔ्',
'ञऔॎ',
'ञऔॏ',
'ञऔॢ',
'ञऔॣ',
'टअ',
'टअऀ',
'टअँ',
'टअं',
'टअः',
'टअऺ',
'टअऻ',
'टअ़',
'टअा',
'टअि',
'टअी',
'टअु',
'टअू',
'टअृ',
'टअॄ',
'टअॅ',
'टअॆ',
'टअे',
'टअै',
'टअॉ',
'टअॊ',
'टअो',
'टअौ',
'टअ्',
'टअॎ',
'टअॏ',
'टअॢ',
'टअॣ',
'टइ',
'टइऀ',
'टइँ',
'टइं',
'टइः',
'टइऺ',
'टइऻ',
'टइ़',
'टइा',
'टइि',
'टइी',
'टइु',
'टइू',
'टइृ',
'टइॄ',
'टइॅ',
'टइॆ',
'टइे',
'टइै',
'टइॉ',
'टइॊ',
'टइो',
'टइौ',
'टइ्',
'टइॎ',
'टइॏ',
'टइॢ',
'टइॣ',
'टउ',
'टउऀ',
'टउँ',
'टउं',
'टउः',
'टउऺ',
'टउऻ',
'टउ़',
'टउा',
'टउि',
'टउी',
'टउु',
'टउू',
'टउृ',
'टउॄ',
'टउॅ',
'टउॆ',
'टउे',
'टउै',
'टउॉ',
'टउॊ',
'टउो',
'टउौ',
'टउ्',
'टउॎ',
'टउॏ',
'टउॢ',
'टउॣ',
'टऋ',
'टऋऀ',
'टऋँ',
'टऋं',
'टऋः',
'टऋऺ',
'टऋऻ',
'टऋ़',
'टऋा',
'टऋि',
'टऋी',
'टऋु',
'टऋू',
'टऋृ',
'टऋॄ',
'टऋॅ',
'टऋॆ',
'टऋे',
'टऋै',
'टऋॉ',
'टऋॊ',
'टऋो',
'टऋौ',
'टऋ्',
'टऋॎ',
'टऋॏ',
'टऋॢ',
'टऋॣ',
'टऌ',
'टऌऀ',
'टऌँ',
'टऌं',
'टऌः',
'टऌऺ',
'टऌऻ',
'टऌ़',
'टऌा',
'टऌि',
'टऌी',
'टऌु',
'टऌू',
'टऌृ',
'टऌॄ',
'टऌॅ',
'टऌॆ',
'टऌे',
'टऌै',
'टऌॉ',
'टऌॊ',
'टऌो',
'टऌौ',
'टऌ्',
'टऌॎ',
'टऌॏ',
'टऌॢ',
'टऌॣ',
'टए',
'टएऀ',
'टएँ',
'टएं',
'टएः',
'टएऺ',
'टएऻ',
'टए़',
'टएा',
'टएि',
'टएी',
'टएु',
'टएू',
'टएृ',
'टएॄ',
'टएॅ',
'टएॆ',
'टएे',
'टएै',
'टएॉ',
'टएॊ',
'टएो',
'टएौ',
'टए्',
'टएॎ',
'टएॏ',
'टएॢ',
'टएॣ',
'टऐ',
'टऐऀ',
'टऐँ',
'टऐं',
'टऐः',
'टऐऺ',
'टऐऻ',
'टऐ़',
'टऐा',
'टऐि',
'टऐी',
'टऐु',
'टऐू',
'टऐृ',
'टऐॄ',
'टऐॅ',
'टऐॆ',
'टऐे',
'टऐै',
'टऐॉ',
'टऐॊ',
'टऐो',
'टऐौ',
'टऐ्',
'टऐॎ',
'टऐॏ',
'टऐॢ',
'टऐॣ',
'टओ',
'टओऀ',
'टओँ',
'टओं',
'टओः',
'टओऺ',
'टओऻ',
'टओ़',
'टओा',
'टओि',
'टओी',
'टओु',
'टओू',
'टओृ',
'टओॄ',
'टओॅ',
'टओॆ',
'टओे',
'टओै',
'टओॉ',
'टओॊ',
'टओो',
'टओौ',
'टओ्',
'टओॎ',
'टओॏ',
'टओॢ',
'टओॣ',
'टऔ',
'टऔऀ',
'टऔँ',
'टऔं',
'टऔः',
'टऔऺ',
'टऔऻ',
'टऔ़',
'टऔा',
'टऔि',
'टऔी',
'टऔु',
'टऔू',
'टऔृ',
'टऔॄ',
'टऔॅ',
'टऔॆ',
'टऔे',
'टऔै',
'टऔॉ',
'टऔॊ',
'टऔो',
'टऔौ',
'टऔ्',
'टऔॎ',
'टऔॏ',
'टऔॢ',
'टऔॣ',
'ठअ',
'ठअऀ',
'ठअँ',
'ठअं',
'ठअः',
'ठअऺ',
'ठअऻ',
'ठअ़',
'ठअा',
'ठअि',
'ठअी',
'ठअु',
'ठअू',
'ठअृ',
'ठअॄ',
'ठअॅ',
'ठअॆ',
'ठअे',
'ठअै',
'ठअॉ',
'ठअॊ',
'ठअो',
'ठअौ',
'ठअ्',
'ठअॎ',
'ठअॏ',
'ठअॢ',
'ठअॣ',
'ठइ',
'ठइऀ',
'ठइँ',
'ठइं',
'ठइः',
'ठइऺ',
'ठइऻ',
'ठइ़',
'ठइा',
'ठइि',
'ठइी',
'ठइु',
'ठइू',
'ठइृ',
'ठइॄ',
'ठइॅ',
'ठइॆ',
'ठइे',
'ठइै',
'ठइॉ',
'ठइॊ',
'ठइो',
'ठइौ',
'ठइ्',
'ठइॎ',
'ठइॏ',
'ठइॢ',
'ठइॣ',
'ठउ',
'ठउऀ',
'ठउँ',
'ठउं',
'ठउः',
'ठउऺ',
'ठउऻ',
'ठउ़',
'ठउा',
'ठउि',
'ठउी',
'ठउु',
'ठउू',
'ठउृ',
'ठउॄ',
'ठउॅ',
'ठउॆ',
'ठउे',
'ठउै',
'ठउॉ',
'ठउॊ',
'ठउो',
'ठउौ',
'ठउ्',
'ठउॎ',
'ठउॏ',
'ठउॢ',
'ठउॣ',
'ठऋ',
'ठऋऀ',
'ठऋँ',
'ठऋं',
'ठऋः',
'ठऋऺ',
'ठऋऻ',
'ठऋ़',
'ठऋा',
'ठऋि',
'ठऋी',
'ठऋु',
'ठऋू',
'ठऋृ',
'ठऋॄ',
'ठऋॅ',
'ठऋॆ',
'ठऋे',
'ठऋै',
'ठऋॉ',
'ठऋॊ',
'ठऋो',
'ठऋौ',
'ठऋ्',
'ठऋॎ',
'ठऋॏ',
'ठऋॢ',
'ठऋॣ',
'ठऌ',
'ठऌऀ',
'ठऌँ',
'ठऌं',
'ठऌः',
'ठऌऺ',
'ठऌऻ',
'ठऌ़',
'ठऌा',
'ठऌि',
'ठऌी',
'ठऌु',
'ठऌू',
'ठऌृ',
'ठऌॄ',
'ठऌॅ',
'ठऌॆ',
'ठऌे',
'ठऌै',
'ठऌॉ',
'ठऌॊ',
'ठऌो',
'ठऌौ',
'ठऌ्',
'ठऌॎ',
'ठऌॏ',
'ठऌॢ',
'ठऌॣ',
'ठए',
'ठएऀ',
'ठएँ',
'ठएं',
'ठएः',
'ठएऺ',
'ठएऻ',
'ठए़',
'ठएा',
'ठएि',
'ठएी',
'ठएु',
'ठएू',
'ठएृ',
'ठएॄ',
'ठएॅ',
'ठएॆ',
'ठएे',
'ठएै',
'ठएॉ',
'ठएॊ',
'ठएो',
'ठएौ',
'ठए्',
'ठएॎ',
'ठएॏ',
'ठएॢ',
'ठएॣ',
'ठऐ',
'ठऐऀ',
'ठऐँ',
'ठऐं',
'ठऐः',
'ठऐऺ',
'ठऐऻ',
'ठऐ़',
'ठऐा',
'ठऐि',
'ठऐी',
'ठऐु',
'ठऐू',
'ठऐृ',
'ठऐॄ',
'ठऐॅ',
'ठऐॆ',
'ठऐे',
'ठऐै',
'ठऐॉ',
'ठऐॊ',
'ठऐो',
'ठऐौ',
'ठऐ्',
'ठऐॎ',
'ठऐॏ',
'ठऐॢ',
'ठऐॣ',
'ठओ',
'ठओऀ',
'ठओँ',
'ठओं',
'ठओः',
'ठओऺ',
'ठओऻ',
'ठओ़',
'ठओा',
'ठओि',
'ठओी',
'ठओु',
'ठओू',
'ठओृ',
'ठओॄ',
'ठओॅ',
'ठओॆ',
'ठओे',
'ठओै',
'ठओॉ',
'ठओॊ',
'ठओो',
'ठओौ',
'ठओ्',
'ठओॎ',
'ठओॏ',
'ठओॢ',
'ठओॣ',
'ठऔ',
'ठऔऀ',
'ठऔँ',
'ठऔं',
'ठऔः',
'ठऔऺ',
'ठऔऻ',
'ठऔ़',
'ठऔा',
'ठऔि',
'ठऔी',
'ठऔु',
'ठऔू',
'ठऔृ',
'ठऔॄ',
'ठऔॅ',
'ठऔॆ',
'ठऔे',
'ठऔै',
'ठऔॉ',
'ठऔॊ',
'ठऔो',
'ठऔौ',
'ठऔ्',
'ठऔॎ',
'ठऔॏ',
'ठऔॢ',
'ठऔॣ',
'डअ',
'डअऀ',
'डअँ',
'डअं',
'डअः',
'डअऺ',
'डअऻ',
'डअ़',
'डअा',
'डअि',
'डअी',
'डअु',
'डअू',
'डअृ',
'डअॄ',
'डअॅ',
'डअॆ',
'डअे',
'डअै',
'डअॉ',
'डअॊ',
'डअो',
'डअौ',
'डअ्',
'डअॎ',
'डअॏ',
'डअॢ',
'डअॣ',
'डइ',
'डइऀ',
'डइँ',
'डइं',
'डइः',
'डइऺ',
'डइऻ',
'डइ़',
'डइा',
'डइि',
'डइी',
'डइु',
'डइू',
'डइृ',
'डइॄ',
'डइॅ',
'डइॆ',
'डइे',
'डइै',
'डइॉ',
'डइॊ',
'डइो',
'डइौ',
'डइ्',
'डइॎ',
'डइॏ',
'डइॢ',
'डइॣ',
'डउ',
'डउऀ',
'डउँ',
'डउं',
'डउः',
'डउऺ',
'डउऻ',
'डउ़',
'डउा',
'डउि',
'डउी',
'डउु',
'डउू',
'डउृ',
'डउॄ',
'डउॅ',
'डउॆ',
'डउे',
'डउै',
'डउॉ',
'डउॊ',
'डउो',
'डउौ',
'डउ्',
'डउॎ',
'डउॏ',
'डउॢ',
'डउॣ',
'डऋ',
'डऋऀ',
'डऋँ',
'डऋं',
'डऋः',
'डऋऺ',
'डऋऻ',
'डऋ़',
'डऋा',
'डऋि',
'डऋी',
'डऋु',
'डऋू',
'डऋृ',
'डऋॄ',
'डऋॅ',
'डऋॆ',
'डऋे',
'डऋै',
'डऋॉ',
'डऋॊ',
'डऋो',
'डऋौ',
'डऋ्',
'डऋॎ',
'डऋॏ',
'डऋॢ',
'डऋॣ',
'डऌ',
'डऌऀ',
'डऌँ',
'डऌं',
'डऌः',
'डऌऺ',
'डऌऻ',
'डऌ़',
'डऌा',
'डऌि',
'डऌी',
'डऌु',
'डऌू',
'डऌृ',
'डऌॄ',
'डऌॅ',
'डऌॆ',
'डऌे',
'डऌै',
'डऌॉ',
'डऌॊ',
'डऌो',
'डऌौ',
'डऌ्',
'डऌॎ',
'डऌॏ',
'डऌॢ',
'डऌॣ',
'डए',
'डएऀ',
'डएँ',
'डएं',
'डएः',
'डएऺ',
'डएऻ',
'डए़',
'डएा',
'डएि',
'डएी',
'डएु',
'डएू',
'डएृ',
'डएॄ',
'डएॅ',
'डएॆ',
'डएे',
'डएै',
'डएॉ',
'डएॊ',
'डएो',
'डएौ',
'डए्',
'डएॎ',
'डएॏ',
'डएॢ',
'डएॣ',
'डऐ',
'डऐऀ',
'डऐँ',
'डऐं',
'डऐः',
'डऐऺ',
'डऐऻ',
'डऐ़',
'डऐा',
'डऐि',
'डऐी',
'डऐु',
'डऐू',
'डऐृ',
'डऐॄ',
'डऐॅ',
'डऐॆ',
'डऐे',
'डऐै',
'डऐॉ',
'डऐॊ',
'डऐो',
'डऐौ',
'डऐ्',
'डऐॎ',
'डऐॏ',
'डऐॢ',
'डऐॣ',
'डओ',
'डओऀ',
'डओँ',
'डओं',
'डओः',
'डओऺ',
'डओऻ',
'डओ़',
'डओा',
'डओि',
'डओी',
'डओु',
'डओू',
'डओृ',
'डओॄ',
'डओॅ',
'डओॆ',
'डओे',
'डओै',
'डओॉ',
'डओॊ',
'डओो',
'डओौ',
'डओ्',
'डओॎ',
'डओॏ',
'डओॢ',
'डओॣ',
'डऔ',
'डऔऀ',
'डऔँ',
'डऔं',
'डऔः',
'डऔऺ',
'डऔऻ',
'डऔ़',
'डऔा',
'डऔि',
'डऔी',
'डऔु',
'डऔू',
'डऔृ',
'डऔॄ',
'डऔॅ',
'डऔॆ',
'डऔे',
'डऔै',
'डऔॉ',
'डऔॊ',
'डऔो',
'डऔौ',
'डऔ्',
'डऔॎ',
'डऔॏ',
'डऔॢ',
'डऔॣ',
'ढअ',
'ढअऀ',
'ढअँ',
'ढअं',
'ढअः',
'ढअऺ',
'ढअऻ',
'ढअ़',
'ढअा',
'ढअि',
'ढअी',
'ढअु',
'ढअू',
'ढअृ',
'ढअॄ',
'ढअॅ',
'ढअॆ',
'ढअे',
'ढअै',
'ढअॉ',
'ढअॊ',
'ढअो',
'ढअौ',
'ढअ्',
'ढअॎ',
'ढअॏ',
'ढअॢ',
'ढअॣ',
'ढइ',
'ढइऀ',
'ढइँ',
'ढइं',
'ढइः',
'ढइऺ',
'ढइऻ',
'ढइ़',
'ढइा',
'ढइि',
'ढइी',
'ढइु',
'ढइू',
'ढइृ',
'ढइॄ',
'ढइॅ',
'ढइॆ',
'ढइे',
'ढइै',
'ढइॉ',
'ढइॊ',
'ढइो',
'ढइौ',
'ढइ्',
'ढइॎ',
'ढइॏ',
'ढइॢ',
'ढइॣ',
'ढउ',
'ढउऀ',
'ढउँ',
'ढउं',
'ढउः',
'ढउऺ',
'ढउऻ',
'ढउ़',
'ढउा',
'ढउि',
'ढउी',
'ढउु',
'ढउू',
'ढउृ',
'ढउॄ',
'ढउॅ',
'ढउॆ',
'ढउे',
'ढउै',
'ढउॉ',
'ढउॊ',
'ढउो',
'ढउौ',
'ढउ्',
'ढउॎ',
'ढउॏ',
'ढउॢ',
'ढउॣ',
'ढऋ',
'ढऋऀ',
'ढऋँ',
'ढऋं',
'ढऋः',
'ढऋऺ',
'ढऋऻ',
'ढऋ़',
'ढऋा',
'ढऋि',
'ढऋी',
'ढऋु',
'ढऋू',
'ढऋृ',
'ढऋॄ',
'ढऋॅ',
'ढऋॆ',
'ढऋे',
'ढऋै',
'ढऋॉ',
'ढऋॊ',
'ढऋो',
'ढऋौ',
'ढऋ्',
'ढऋॎ',
'ढऋॏ',
'ढऋॢ',
'ढऋॣ',
'ढऌ',
'ढऌऀ',
'ढऌँ',
'ढऌं',
'ढऌः',
'ढऌऺ',
'ढऌऻ',
'ढऌ़',
'ढऌा',
'ढऌि',
'ढऌी',
'ढऌु',
'ढऌू',
'ढऌृ',
'ढऌॄ',
'ढऌॅ',
'ढऌॆ',
'ढऌे',
'ढऌै',
'ढऌॉ',
'ढऌॊ',
'ढऌो',
'ढऌौ',
'ढऌ्',
'ढऌॎ',
'ढऌॏ',
'ढऌॢ',
'ढऌॣ',
'ढए',
'ढएऀ',
'ढएँ',
'ढएं',
'ढएः',
'ढएऺ',
'ढएऻ',
'ढए़',
'ढएा',
'ढएि',
'ढएी',
'ढएु',
'ढएू',
'ढएृ',
'ढएॄ',
'ढएॅ',
'ढएॆ',
'ढएे',
'ढएै',
'ढएॉ',
'ढएॊ',
'ढएो',
'ढएौ',
'ढए्',
'ढएॎ',
'ढएॏ',
'ढएॢ',
'ढएॣ',
'ढऐ',
'ढऐऀ',
'ढऐँ',
'ढऐं',
'ढऐः',
'ढऐऺ',
'ढऐऻ',
'ढऐ़',
'ढऐा',
'ढऐि',
'ढऐी',
'ढऐु',
'ढऐू',
'ढऐृ',
'ढऐॄ',
'ढऐॅ',
'ढऐॆ',
'ढऐे',
'ढऐै',
'ढऐॉ',
'ढऐॊ',
'ढऐो',
'ढऐौ',
'ढऐ्',
'ढऐॎ',
'ढऐॏ',
'ढऐॢ',
'ढऐॣ',
'ढओ',
'ढओऀ',
'ढओँ',
'ढओं',
'ढओः',
'ढओऺ',
'ढओऻ',
'ढओ़',
'ढओा',
'ढओि',
'ढओी',
'ढओु',
'ढओू',
'ढओृ',
'ढओॄ',
'ढओॅ',
'ढओॆ',
'ढओे',
'ढओै',
'ढओॉ',
'ढओॊ',
'ढओो',
'ढओौ',
'ढओ्',
'ढओॎ',
'ढओॏ',
'ढओॢ',
'ढओॣ',
'ढऔ',
'ढऔऀ',
'ढऔँ',
'ढऔं',
'ढऔः',
'ढऔऺ',
'ढऔऻ',
'ढऔ़',
'ढऔा',
'ढऔि',
'ढऔी',
'ढऔु',
'ढऔू',
'ढऔृ',
'ढऔॄ',
'ढऔॅ',
'ढऔॆ',
'ढऔे',
'ढऔै',
'ढऔॉ',
'ढऔॊ',
'ढऔो',
'ढऔौ',
'ढऔ्',
'ढऔॎ',
'ढऔॏ',
'ढऔॢ',
'ढऔॣ',
'णअ',
'णअऀ',
'णअँ',
'णअं',
'णअः',
'णअऺ',
'णअऻ',
'णअ़',
'णअा',
'णअि',
'णअी',
'णअु',
'णअू',
'णअृ',
'णअॄ',
'णअॅ',
'णअॆ',
'णअे',
'णअै',
'णअॉ',
'णअॊ',
'णअो',
'णअौ',
'णअ्',
'णअॎ',
'णअॏ',
'णअॢ',
'णअॣ',
'णइ',
'णइऀ',
'णइँ',
'णइं',
'णइः',
'णइऺ',
'णइऻ',
'णइ़',
'णइा',
'णइि',
'णइी',
'णइु',
'णइू',
'णइृ',
'णइॄ',
'णइॅ',
'णइॆ',
'णइे',
'णइै',
'णइॉ',
'णइॊ',
'णइो',
'णइौ',
'णइ्',
'णइॎ',
'णइॏ',
'णइॢ',
'णइॣ',
'णउ',
'णउऀ',
'णउँ',
'णउं',
'णउः',
'णउऺ',
'णउऻ',
'णउ़',
'णउा',
'णउि',
'णउी',
'णउु',
'णउू',
'णउृ',
'णउॄ',
'णउॅ',
'णउॆ',
'णउे',
'णउै',
'णउॉ',
'णउॊ',
'णउो',
'णउौ',
'णउ्',
'णउॎ',
'णउॏ',
'णउॢ',
'णउॣ',
'णऋ',
'णऋऀ',
'णऋँ',
'णऋं',
'णऋः',
'णऋऺ',
'णऋऻ',
'णऋ़',
'णऋा',
'णऋि',
'णऋी',
'णऋु',
'णऋू',
'णऋृ',
'णऋॄ',
'णऋॅ',
'णऋॆ',
'णऋे',
'णऋै',
'णऋॉ',
'णऋॊ',
'णऋो',
'णऋौ',
'णऋ्',
'णऋॎ',
'णऋॏ',
'णऋॢ',
'णऋॣ',
'णऌ',
'णऌऀ',
'णऌँ',
'णऌं',
'णऌः',
'णऌऺ',
'णऌऻ',
'णऌ़',
'णऌा',
'णऌि',
'णऌी',
'णऌु',
'णऌू',
'णऌृ',
'णऌॄ',
'णऌॅ',
'णऌॆ',
'णऌे',
'णऌै',
'णऌॉ',
'णऌॊ',
'णऌो',
'णऌौ',
'णऌ्',
'णऌॎ',
'णऌॏ',
'णऌॢ',
'णऌॣ',
'णए',
'णएऀ',
'णएँ',
'णएं',
'णएः',
'णएऺ',
'णएऻ',
'णए़',
'णएा',
'णएि',
'णएी',
'णएु',
'णएू',
'णएृ',
'णएॄ',
'णएॅ',
'णएॆ',
'णएे',
'णएै',
'णएॉ',
'णएॊ',
'णएो',
'णएौ',
'णए्',
'णएॎ',
'णएॏ',
'णएॢ',
'णएॣ',
'णऐ',
'णऐऀ',
'णऐँ',
'णऐं',
'णऐः',
'णऐऺ',
'णऐऻ',
'णऐ़',
'णऐा',
'णऐि',
'णऐी',
'णऐु',
'णऐू',
'णऐृ',
'णऐॄ',
'णऐॅ',
'णऐॆ',
'णऐे',
'णऐै',
'णऐॉ',
'णऐॊ',
'णऐो',
'णऐौ',
'णऐ्',
'णऐॎ',
'णऐॏ',
'णऐॢ',
'णऐॣ',
'णओ',
'णओऀ',
'णओँ',
'णओं',
'णओः',
'णओऺ',
'णओऻ',
'णओ़',
'णओा',
'णओि',
'णओी',
'णओु',
'णओू',
'णओृ',
'णओॄ',
'णओॅ',
'णओॆ',
'णओे',
'णओै',
'णओॉ',
'णओॊ',
'णओो',
'णओौ',
'णओ्',
'णओॎ',
'णओॏ',
'णओॢ',
'णओॣ',
'णऔ',
'णऔऀ',
'णऔँ',
'णऔं',
'णऔः',
'णऔऺ',
'णऔऻ',
'णऔ़',
'णऔा',
'णऔि',
'णऔी',
'णऔु',
'णऔू',
'णऔृ',
'णऔॄ',
'णऔॅ',
'णऔॆ',
'णऔे',
'णऔै',
'णऔॉ',
'णऔॊ',
'णऔो',
'णऔौ',
'णऔ्',
'णऔॎ',
'णऔॏ',
'णऔॢ',
'णऔॣ',
'तअ',
'तअऀ',
'तअँ',
'तअं',
'तअः',
'तअऺ',
'तअऻ',
'तअ़',
'तअा',
'तअि',
'तअी',
'तअु',
'तअू',
'तअृ',
'तअॄ',
'तअॅ',
'तअॆ',
'तअे',
'तअै',
'तअॉ',
'तअॊ',
'तअो',
'तअौ',
'तअ्',
'तअॎ',
'तअॏ',
'तअॢ',
'तअॣ',
'तइ',
'तइऀ',
'तइँ',
'तइं',
'तइः',
'तइऺ',
'तइऻ',
'तइ़',
'तइा',
'तइि',
'तइी',
'तइु',
'तइू',
'तइृ',
'तइॄ',
'तइॅ',
'तइॆ',
'तइे',
'तइै',
'तइॉ',
'तइॊ',
'तइो',
'तइौ',
'तइ्',
'तइॎ',
'तइॏ',
'तइॢ',
'तइॣ',
'तउ',
'तउऀ',
'तउँ',
'तउं',
'तउः',
'तउऺ',
'तउऻ',
'तउ़',
'तउा',
'तउि',
'तउी',
'तउु',
'तउू',
'तउृ',
'तउॄ',
'तउॅ',
'तउॆ',
'तउे',
'तउै',
'तउॉ',
'तउॊ',
'तउो',
'तउौ',
'तउ्',
'तउॎ',
'तउॏ',
'तउॢ',
'तउॣ',
'तऋ',
'तऋऀ',
'तऋँ',
'तऋं',
'तऋः',
'तऋऺ',
'तऋऻ',
'तऋ़',
'तऋा',
'तऋि',
'तऋी',
'तऋु',
'तऋू',
'तऋृ',
'तऋॄ',
'तऋॅ',
'तऋॆ',
'तऋे',
'तऋै',
'तऋॉ',
'तऋॊ',
'तऋो',
'तऋौ',
'तऋ्',
'तऋॎ',
'तऋॏ',
'तऋॢ',
'तऋॣ',
'तऌ',
'तऌऀ',
'तऌँ',
'तऌं',
'तऌः',
'तऌऺ',
'तऌऻ',
'तऌ़',
'तऌा',
'तऌि',
'तऌी',
'तऌु',
'तऌू',
'तऌृ',
'तऌॄ',
'तऌॅ',
'तऌॆ',
'तऌे',
'तऌै',
'तऌॉ',
'तऌॊ',
'तऌो',
'तऌौ',
'तऌ्',
'तऌॎ',
'तऌॏ',
'तऌॢ',
'तऌॣ',
'तए',
'तएऀ',
'तएँ',
'तएं',
'तएः',
'तएऺ',
'तएऻ',
'तए़',
'तएा',
'तएि',
'तएी',
'तएु',
'तएू',
'तएृ',
'तएॄ',
'तएॅ',
'तएॆ',
'तएे',
'तएै',
'तएॉ',
'तएॊ',
'तएो',
'तएौ',
'तए्',
'तएॎ',
'तएॏ',
'तएॢ',
'तएॣ',
'तऐ',
'तऐऀ',
'तऐँ',
'तऐं',
'तऐः',
'तऐऺ',
'तऐऻ',
'तऐ़',
'तऐा',
'तऐि',
'तऐी',
'तऐु',
'तऐू',
'तऐृ',
'तऐॄ',
'तऐॅ',
'तऐॆ',
'तऐे',
'तऐै',
'तऐॉ',
'तऐॊ',
'तऐो',
'तऐौ',
'तऐ्',
'तऐॎ',
'तऐॏ',
'तऐॢ',
'तऐॣ',
'तओ',
'तओऀ',
'तओँ',
'तओं',
'तओः',
'तओऺ',
'तओऻ',
'तओ़',
'तओा',
'तओि',
'तओी',
'तओु',
'तओू',
'तओृ',
'तओॄ',
'तओॅ',
'तओॆ',
'तओे',
'तओै',
'तओॉ',
'तओॊ',
'तओो',
'तओौ',
'तओ्',
'तओॎ',
'तओॏ',
'तओॢ',
'तओॣ',
'तऔ',
'तऔऀ',
'तऔँ',
'तऔं',
'तऔः',
'तऔऺ',
'तऔऻ',
'तऔ़',
'तऔा',
'तऔि',
'तऔी',
'तऔु',
'तऔू',
'तऔृ',
'तऔॄ',
'तऔॅ',
'तऔॆ',
'तऔे',
'तऔै',
'तऔॉ',
'तऔॊ',
'तऔो',
'तऔौ',
'तऔ्',
'तऔॎ',
'तऔॏ',
'तऔॢ',
'तऔॣ',
'थअ',
'थअऀ',
'थअँ',
'थअं',
'थअः',
'थअऺ',
'थअऻ',
'थअ़',
'थअा',
'थअि',
'थअी',
'थअु',
'थअू',
'थअृ',
'थअॄ',
'थअॅ',
'थअॆ',
'थअे',
'थअै',
'थअॉ',
'थअॊ',
'थअो',
'थअौ',
'थअ्',
'थअॎ',
'थअॏ',
'थअॢ',
'थअॣ',
'थइ',
'थइऀ',
'थइँ',
'थइं',
'थइः',
'थइऺ',
'थइऻ',
'थइ़',
'थइा',
'थइि',
'थइी',
'थइु',
'थइू',
'थइृ',
'थइॄ',
'थइॅ',
'थइॆ',
'थइे',
'थइै',
'थइॉ',
'थइॊ',
'थइो',
'थइौ',
'थइ्',
'थइॎ',
'थइॏ',
'थइॢ',
'थइॣ',
'थउ',
'थउऀ',
'थउँ',
'थउं',
'थउः',
'थउऺ',
'थउऻ',
'थउ़',
'थउा',
'थउि',
'थउी',
'थउु',
'थउू',
'थउृ',
'थउॄ',
'थउॅ',
'थउॆ',
'थउे',
'थउै',
'थउॉ',
'थउॊ',
'थउो',
'थउौ',
'थउ्',
'थउॎ',
'थउॏ',
'थउॢ',
'थउॣ',
'थऋ',
'थऋऀ',
'थऋँ',
'थऋं',
'थऋः',
'थऋऺ',
'थऋऻ',
'थऋ़',
'थऋा',
'थऋि',
'थऋी',
'थऋु',
'थऋू',
'थऋृ',
'थऋॄ',
'थऋॅ',
'थऋॆ',
'थऋे',
'थऋै',
'थऋॉ',
'थऋॊ',
'थऋो',
'थऋौ',
'थऋ्',
'थऋॎ',
'थऋॏ',
'थऋॢ',
'थऋॣ',
'थऌ',
'थऌऀ',
'थऌँ',
'थऌं',
'थऌः',
'थऌऺ',
'थऌऻ',
'थऌ़',
'थऌा',
'थऌि',
'थऌी',
'थऌु',
'थऌू',
'थऌृ',
'थऌॄ',
'थऌॅ',
'थऌॆ',
'थऌे',
'थऌै',
'थऌॉ',
'थऌॊ',
'थऌो',
'थऌौ',
'थऌ्',
'थऌॎ',
'थऌॏ',
'थऌॢ',
'थऌॣ',
'थए',
'थएऀ',
'थएँ',
'थएं',
'थएः',
'थएऺ',
'थएऻ',
'थए़',
'थएा',
'थएि',
'थएी',
'थएु',
'थएू',
'थएृ',
'थएॄ',
'थएॅ',
'थएॆ',
'थएे',
'थएै',
'थएॉ',
'थएॊ',
'थएो',
'थएौ',
'थए्',
'थएॎ',
'थएॏ',
'थएॢ',
'थएॣ',
'थऐ',
'थऐऀ',
'थऐँ',
'थऐं',
'थऐः',
'थऐऺ',
'थऐऻ',
'थऐ़',
'थऐा',
'थऐि',
'थऐी',
'थऐु',
'थऐू',
'थऐृ',
'थऐॄ',
'थऐॅ',
'थऐॆ',
'थऐे',
'थऐै',
'थऐॉ',
'थऐॊ',
'थऐो',
'थऐौ',
'थऐ्',
'थऐॎ',
'थऐॏ',
'थऐॢ',
'थऐॣ',
'थओ',
'थओऀ',
'थओँ',
'थओं',
'थओः',
'थओऺ',
'थओऻ',
'थओ़',
'थओा',
'थओि',
'थओी',
'थओु',
'थओू',
'थओृ',
'थओॄ',
'थओॅ',
'थओॆ',
'थओे',
'थओै',
'थओॉ',
'थओॊ',
'थओो',
'थओौ',
'थओ्',
'थओॎ',
'थओॏ',
'थओॢ',
'थओॣ',
'थऔ',
'थऔऀ',
'थऔँ',
'थऔं',
'थऔः',
'थऔऺ',
'थऔऻ',
'थऔ़',
'थऔा',
'थऔि',
'थऔी',
'थऔु',
'थऔू',
'थऔृ',
'थऔॄ',
'थऔॅ',
'थऔॆ',
'थऔे',
'थऔै',
'थऔॉ',
'थऔॊ',
'थऔो',
'थऔौ',
'थऔ्',
'थऔॎ',
'थऔॏ',
'थऔॢ',
'थऔॣ',
'दअ',
'दअऀ',
'दअँ',
'दअं',
'दअः',
'दअऺ',
'दअऻ',
'दअ़',
'दअा',
'दअि',
'दअी',
'दअु',
'दअू',
'दअृ',
'दअॄ',
'दअॅ',
'दअॆ',
'दअे',
'दअै',
'दअॉ',
'दअॊ',
'दअो',
'दअौ',
'दअ्',
'दअॎ',
'दअॏ',
'दअॢ',
'दअॣ',
'दइ',
'दइऀ',
'दइँ',
'दइं',
'दइः',
'दइऺ',
'दइऻ',
'दइ़',
'दइा',
'दइि',
'दइी',
'दइु',
'दइू',
'दइृ',
'दइॄ',
'दइॅ',
'दइॆ',
'दइे',
'दइै',
'दइॉ',
'दइॊ',
'दइो',
'दइौ',
'दइ्',
'दइॎ',
'दइॏ',
'दइॢ',
'दइॣ',
'दउ',
'दउऀ',
'दउँ',
'दउं',
'दउः',
'दउऺ',
'दउऻ',
'दउ़',
'दउा',
'दउि',
'दउी',
'दउु',
'दउू',
'दउृ',
'दउॄ',
'दउॅ',
'दउॆ',
'दउे',
'दउै',
'दउॉ',
'दउॊ',
'दउो',
'दउौ',
'दउ्',
'दउॎ',
'दउॏ',
'दउॢ',
'दउॣ',
'दऋ',
'दऋऀ',
'दऋँ',
'दऋं',
'दऋः',
'दऋऺ',
'दऋऻ',
'दऋ़',
'दऋा',
'दऋि',
'दऋी',
'दऋु',
'दऋू',
'दऋृ',
'दऋॄ',
'दऋॅ',
'दऋॆ',
'दऋे',
'दऋै',
'दऋॉ',
'दऋॊ',
'दऋो',
'दऋौ',
'दऋ्',
'दऋॎ',
'दऋॏ',
'दऋॢ',
'दऋॣ',
'दऌ',
'दऌऀ',
'दऌँ',
'दऌं',
'दऌः',
'दऌऺ',
'दऌऻ',
'दऌ़',
'दऌा',
'दऌि',
'दऌी',
'दऌु',
'दऌू',
'दऌृ',
'दऌॄ',
'दऌॅ',
'दऌॆ',
'दऌे',
'दऌै',
'दऌॉ',
'दऌॊ',
'दऌो',
'दऌौ',
'दऌ्',
'दऌॎ',
'दऌॏ',
'दऌॢ',
'दऌॣ',
'दए',
'दएऀ',
'दएँ',
'दएं',
'दएः',
'दएऺ',
'दएऻ',
'दए़',
'दएा',
'दएि',
'दएी',
'दएु',
'दएू',
'दएृ',
'दएॄ',
'दएॅ',
'दएॆ',
'दएे',
'दएै',
'दएॉ',
'दएॊ',
'दएो',
'दएौ',
'दए्',
'दएॎ',
'दएॏ',
'दएॢ',
'दएॣ',
'दऐ',
'दऐऀ',
'दऐँ',
'दऐं',
'दऐः',
'दऐऺ',
'दऐऻ',
'दऐ़',
'दऐा',
'दऐि',
'दऐी',
'दऐु',
'दऐू',
'दऐृ',
'दऐॄ',
'दऐॅ',
'दऐॆ',
'दऐे',
'दऐै',
'दऐॉ',
'दऐॊ',
'दऐो',
'दऐौ',
'दऐ्',
'दऐॎ',
'दऐॏ',
'दऐॢ',
'दऐॣ',
'दओ',
'दओऀ',
'दओँ',
'दओं',
'दओः',
'दओऺ',
'दओऻ',
'दओ़',
'दओा',
'दओि',
'दओी',
'दओु',
'दओू',
'दओृ',
'दओॄ',
'दओॅ',
'दओॆ',
'दओे',
'दओै',
'दओॉ',
'दओॊ',
'दओो',
'दओौ',
'दओ्',
'दओॎ',
'दओॏ',
'दओॢ',
'दओॣ',
'दऔ',
'दऔऀ',
'दऔँ',
'दऔं',
'दऔः',
'दऔऺ',
'दऔऻ',
'दऔ़',
'दऔा',
'दऔि',
'दऔी',
'दऔु',
'दऔू',
'दऔृ',
'दऔॄ',
'दऔॅ',
'दऔॆ',
'दऔे',
'दऔै',
'दऔॉ',
'दऔॊ',
'दऔो',
'दऔौ',
'दऔ्',
'दऔॎ',
'दऔॏ',
'दऔॢ',
'दऔॣ',
'धअ',
'धअऀ',
'धअँ',
'धअं',
'धअः',
'धअऺ',
'धअऻ',
'धअ़',
'धअा',
'धअि',
'धअी',
'धअु',
'धअू',
'धअृ',
'धअॄ',
'धअॅ',
'धअॆ',
'धअे',
'धअै',
'धअॉ',
'धअॊ',
'धअो',
'धअौ',
'धअ्',
'धअॎ',
'धअॏ',
'धअॢ',
'धअॣ',
'धइ',
'धइऀ',
'धइँ',
'धइं',
'धइः',
'धइऺ',
'धइऻ',
'धइ़',
'धइा',
'धइि',
'धइी',
'धइु',
'धइू',
'धइृ',
'धइॄ',
'धइॅ',
'धइॆ',
'धइे',
'धइै',
'धइॉ',
'धइॊ',
'धइो',
'धइौ',
'धइ्',
'धइॎ',
'धइॏ',
'धइॢ',
'धइॣ',
'धउ',
'धउऀ',
'धउँ',
'धउं',
'धउः',
'धउऺ',
'धउऻ',
'धउ़',
'धउा',
'धउि',
'धउी',
'धउु',
'धउू',
'धउृ',
'धउॄ',
'धउॅ',
'धउॆ',
'धउे',
'धउै',
'धउॉ',
'धउॊ',
'धउो',
'धउौ',
'धउ्',
'धउॎ',
'धउॏ',
'धउॢ',
'धउॣ',
'धऋ',
'धऋऀ',
'धऋँ',
'धऋं',
'धऋः',
'धऋऺ',
'धऋऻ',
'धऋ़',
'धऋा',
'धऋि',
'धऋी',
'धऋु',
'धऋू',
'धऋृ',
'धऋॄ',
'धऋॅ',
'धऋॆ',
'धऋे',
'धऋै',
'धऋॉ',
'धऋॊ',
'धऋो',
'धऋौ',
'धऋ्',
'धऋॎ',
'धऋॏ',
'धऋॢ',
'धऋॣ',
'धऌ',
'धऌऀ',
'धऌँ',
'धऌं',
'धऌः',
'धऌऺ',
'धऌऻ',
'धऌ़',
'धऌा',
'धऌि',
'धऌी',
'धऌु',
'धऌू',
'धऌृ',
'धऌॄ',
'धऌॅ',
'धऌॆ',
'धऌे',
'धऌै',
'धऌॉ',
'धऌॊ',
'धऌो',
'धऌौ',
'धऌ्',
'धऌॎ',
'धऌॏ',
'धऌॢ',
'धऌॣ',
'धए',
'धएऀ',
'धएँ',
'धएं',
'धएः',
'धएऺ',
'धएऻ',
'धए़',
'धएा',
'धएि',
'धएी',
'धएु',
'धएू',
'धएृ',
'धएॄ',
'धएॅ',
'धएॆ',
'धएे',
'धएै',
'धएॉ',
'धएॊ',
'धएो',
'धएौ',
'धए्',
'धएॎ',
'धएॏ',
'धएॢ',
'धएॣ',
'धऐ',
'धऐऀ',
'धऐँ',
'धऐं',
'धऐः',
'धऐऺ',
'धऐऻ',
'धऐ़',
'धऐा',
'धऐि',
'धऐी',
'धऐु',
'धऐू',
'धऐृ',
'धऐॄ',
'धऐॅ',
'धऐॆ',
'धऐे',
'धऐै',
'धऐॉ',
'धऐॊ',
'धऐो',
'धऐौ',
'धऐ्',
'धऐॎ',
'धऐॏ',
'धऐॢ',
'धऐॣ',
'धओ',
'धओऀ',
'धओँ',
'धओं',
'धओः',
'धओऺ',
'धओऻ',
'धओ़',
'धओा',
'धओि',
'धओी',
'धओु',
'धओू',
'धओृ',
'धओॄ',
'धओॅ',
'धओॆ',
'धओे',
'धओै',
'धओॉ',
'धओॊ',
'धओो',
'धओौ',
'धओ्',
'धओॎ',
'धओॏ',
'धओॢ',
'धओॣ',
'धऔ',
'धऔऀ',
'धऔँ',
'धऔं',
'धऔः',
'धऔऺ',
'धऔऻ',
'धऔ़',
'धऔा',
'धऔि',
'धऔी',
'धऔु',
'धऔू',
'धऔृ',
'धऔॄ',
'धऔॅ',
'धऔॆ',
'धऔे',
'धऔै',
'धऔॉ',
'धऔॊ',
'धऔो',
'धऔौ',
'धऔ्',
'धऔॎ',
'धऔॏ',
'धऔॢ',
'धऔॣ',
'नअ',
'नअऀ',
'नअँ',
'नअं',
'नअः',
'नअऺ',
'नअऻ',
'नअ़',
'नअा',
'नअि',
'नअी',
'नअु',
'नअू',
'नअृ',
'नअॄ',
'नअॅ',
'नअॆ',
'नअे',
'नअै',
'नअॉ',
'नअॊ',
'नअो',
'नअौ',
'नअ्',
'नअॎ',
'नअॏ',
'नअॢ',
'नअॣ',
'नइ',
'नइऀ',
'नइँ',
'नइं',
'नइः',
'नइऺ',
'नइऻ',
'नइ़',
'नइा',
'नइि',
'नइी',
'नइु',
'नइू',
'नइृ',
'नइॄ',
'नइॅ',
'नइॆ',
'नइे',
'नइै',
'नइॉ',
'नइॊ',
'नइो',
'नइौ',
'नइ्',
'नइॎ',
'नइॏ',
'नइॢ',
'नइॣ',
'नउ',
'नउऀ',
'नउँ',
'नउं',
'नउः',
'नउऺ',
'नउऻ',
'नउ़',
'नउा',
'नउि',
'नउी',
'नउु',
'नउू',
'नउृ',
'नउॄ',
'नउॅ',
'नउॆ',
'नउे',
'नउै',
'नउॉ',
'नउॊ',
'नउो',
'नउौ',
'नउ्',
'नउॎ',
'नउॏ',
'नउॢ',
'नउॣ',
'नऋ',
'नऋऀ',
'नऋँ',
'नऋं',
'नऋः',
'नऋऺ',
'नऋऻ',
'नऋ़',
'नऋा',
'नऋि',
'नऋी',
'नऋु',
'नऋू',
'नऋृ',
'नऋॄ',
'नऋॅ',
'नऋॆ',
'नऋे',
'नऋै',
'नऋॉ',
'नऋॊ',
'नऋो',
'नऋौ',
'नऋ्',
'नऋॎ',
'नऋॏ',
'नऋॢ',
'नऋॣ',
'नऌ',
'नऌऀ',
'नऌँ',
'नऌं',
'नऌः',
'नऌऺ',
'नऌऻ',
'नऌ़',
'नऌा',
'नऌि',
'नऌी',
'नऌु',
'नऌू',
'नऌृ',
'नऌॄ',
'नऌॅ',
'नऌॆ',
'नऌे',
'नऌै',
'नऌॉ',
'नऌॊ',
'नऌो',
'नऌौ',
'नऌ्',
'नऌॎ',
'नऌॏ',
'नऌॢ',
'नऌॣ',
'नए',
'नएऀ',
'नएँ',
'नएं',
'नएः',
'नएऺ',
'नएऻ',
'नए़',
'नएा',
'नएि',
'नएी',
'नएु',
'नएू',
'नएृ',
'नएॄ',
'नएॅ',
'नएॆ',
'नएे',
'नएै',
'नएॉ',
'नएॊ',
'नएो',
'नएौ',
'नए्',
'नएॎ',
'नएॏ',
'नएॢ',
'नएॣ',
'नऐ',
'नऐऀ',
'नऐँ',
'नऐं',
'नऐः',
'नऐऺ',
'नऐऻ',
'नऐ़',
'नऐा',
'नऐि',
'नऐी',
'नऐु',
'नऐू',
'नऐृ',
'नऐॄ',
'नऐॅ',
'नऐॆ',
'नऐे',
'नऐै',
'नऐॉ',
'नऐॊ',
'नऐो',
'नऐौ',
'नऐ्',
'नऐॎ',
'नऐॏ',
'नऐॢ',
'नऐॣ',
'नओ',
'नओऀ',
'नओँ',
'नओं',
'नओः',
'नओऺ',
'नओऻ',
'नओ़',
'नओा',
'नओि',
'नओी',
'नओु',
'नओू',
'नओृ',
'नओॄ',
'नओॅ',
'नओॆ',
'नओे',
'नओै',
'नओॉ',
'नओॊ',
'नओो',
'नओौ',
'नओ्',
'नओॎ',
'नओॏ',
'नओॢ',
'नओॣ',
'नऔ',
'नऔऀ',
'नऔँ',
'नऔं',
'नऔः',
'नऔऺ',
'नऔऻ',
'नऔ़',
'नऔा',
'नऔि',
'नऔी',
'नऔु',
'नऔू',
'नऔृ',
'नऔॄ',
'नऔॅ',
'नऔॆ',
'नऔे',
'नऔै',
'नऔॉ',
'नऔॊ',
'नऔो',
'नऔौ',
'नऔ्',
'नऔॎ',
'नऔॏ',
'नऔॢ',
'नऔॣ',
'पअ',
'पअऀ',
'पअँ',
'पअं',
'पअः',
'पअऺ',
'पअऻ',
'पअ़',
'पअा',
'पअि',
'पअी',
'पअु',
'पअू',
'पअृ',
'पअॄ',
'पअॅ',
'पअॆ',
'पअे',
'पअै',
'पअॉ',
'पअॊ',
'पअो',
'पअौ',
'पअ्',
'पअॎ',
'पअॏ',
'पअॢ',
'पअॣ',
'पइ',
'पइऀ',
'पइँ',
'पइं',
'पइः',
'पइऺ',
'पइऻ',
'पइ़',
'पइा',
'पइि',
'पइी',
'पइु',
'पइू',
'पइृ',
'पइॄ',
'पइॅ',
'पइॆ',
'पइे',
'पइै',
'पइॉ',
'पइॊ',
'पइो',
'पइौ',
'पइ्',
'पइॎ',
'पइॏ',
'पइॢ',
'पइॣ',
'पउ',
'पउऀ',
'पउँ',
'पउं',
'पउः',
'पउऺ',
'पउऻ',
'पउ़',
'पउा',
'पउि',
'पउी',
'पउु',
'पउू',
'पउृ',
'पउॄ',
'पउॅ',
'पउॆ',
'पउे',
'पउै',
'पउॉ',
'पउॊ',
'पउो',
'पउौ',
'पउ्',
'पउॎ',
'पउॏ',
'पउॢ',
'पउॣ',
'पऋ',
'पऋऀ',
'पऋँ',
'पऋं',
'पऋः',
'पऋऺ',
'पऋऻ',
'पऋ़',
'पऋा',
'पऋि',
'पऋी',
'पऋु',
'पऋू',
'पऋृ',
'पऋॄ',
'पऋॅ',
'पऋॆ',
'पऋे',
'पऋै',
'पऋॉ',
'पऋॊ',
'पऋो',
'पऋौ',
'पऋ्',
'पऋॎ',
'पऋॏ',
'पऋॢ',
'पऋॣ',
'पऌ',
'पऌऀ',
'पऌँ',
'पऌं',
'पऌः',
'पऌऺ',
'पऌऻ',
'पऌ़',
'पऌा',
'पऌि',
'पऌी',
'पऌु',
'पऌू',
'पऌृ',
'पऌॄ',
'पऌॅ',
'पऌॆ',
'पऌे',
'पऌै',
'पऌॉ',
'पऌॊ',
'पऌो',
'पऌौ',
'पऌ्',
'पऌॎ',
'पऌॏ',
'पऌॢ',
'पऌॣ',
'पए',
'पएऀ',
'पएँ',
'पएं',
'पएः',
'पएऺ',
'पएऻ',
'पए़',
'पएा',
'पएि',
'पएी',
'पएु',
'पएू',
'पएृ',
'पएॄ',
'पएॅ',
'पएॆ',
'पएे',
'पएै',
'पएॉ',
'पएॊ',
'पएो',
'पएौ',
'पए्',
'पएॎ',
'पएॏ',
'पएॢ',
'पएॣ',
'पऐ',
'पऐऀ',
'पऐँ',
'पऐं',
'पऐः',
'पऐऺ',
'पऐऻ',
'पऐ़',
'पऐा',
'पऐि',
'पऐी',
'पऐु',
'पऐू',
'पऐृ',
'पऐॄ',
'पऐॅ',
'पऐॆ',
'पऐे',
'पऐै',
'पऐॉ',
'पऐॊ',
'पऐो',
'पऐौ',
'पऐ्',
'पऐॎ',
'पऐॏ',
'पऐॢ',
'पऐॣ',
'पओ',
'पओऀ',
'पओँ',
'पओं',
'पओः',
'पओऺ',
'पओऻ',
'पओ़',
'पओा',
'पओि',
'पओी',
'पओु',
'पओू',
'पओृ',
'पओॄ',
'पओॅ',
'पओॆ',
'पओे',
'पओै',
'पओॉ',
'पओॊ',
'पओो',
'पओौ',
'पओ्',
'पओॎ',
'पओॏ',
'पओॢ',
'पओॣ',
'पऔ',
'पऔऀ',
'पऔँ',
'पऔं',
'पऔः',
'पऔऺ',
'पऔऻ',
'पऔ़',
'पऔा',
'पऔि',
'पऔी',
'पऔु',
'पऔू',
'पऔृ',
'पऔॄ',
'पऔॅ',
'पऔॆ',
'पऔे',
'पऔै',
'पऔॉ',
'पऔॊ',
'पऔो',
'पऔौ',
'पऔ्',
'पऔॎ',
'पऔॏ',
'पऔॢ',
'पऔॣ',
'फअ',
'फअऀ',
'फअँ',
'फअं',
'फअः',
'फअऺ',
'फअऻ',
'फअ़',
'फअा',
'फअि',
'फअी',
'फअु',
'फअू',
'फअृ',
'फअॄ',
'फअॅ',
'फअॆ',
'फअे',
'फअै',
'फअॉ',
'फअॊ',
'फअो',
'फअौ',
'फअ्',
'फअॎ',
'फअॏ',
'फअॢ',
'फअॣ',
'फइ',
'फइऀ',
'फइँ',
'फइं',
'फइः',
'फइऺ',
'फइऻ',
'फइ़',
'फइा',
'फइि',
'फइी',
'फइु',
'फइू',
'फइृ',
'फइॄ',
'फइॅ',
'फइॆ',
'फइे',
'फइै',
'फइॉ',
'फइॊ',
'फइो',
'फइौ',
'फइ्',
'फइॎ',
'फइॏ',
'फइॢ',
'फइॣ',
'फउ',
'फउऀ',
'फउँ',
'फउं',
'फउः',
'फउऺ',
'फउऻ',
'फउ़',
'फउा',
'फउि',
'फउी',
'फउु',
'फउू',
'फउृ',
'फउॄ',
'फउॅ',
'फउॆ',
'फउे',
'फउै',
'फउॉ',
'फउॊ',
'फउो',
'फउौ',
'फउ्',
'फउॎ',
'फउॏ',
'फउॢ',
'फउॣ',
'फऋ',
'फऋऀ',
'फऋँ',
'फऋं',
'फऋः',
'फऋऺ',
'फऋऻ',
'फऋ़',
'फऋा',
'फऋि',
'फऋी',
'फऋु',
'फऋू',
'फऋृ',
'फऋॄ',
'फऋॅ',
'फऋॆ',
'फऋे',
'फऋै',
'फऋॉ',
'फऋॊ',
'फऋो',
'फऋौ',
'फऋ्',
'फऋॎ',
'फऋॏ',
'फऋॢ',
'फऋॣ',
'फऌ',
'फऌऀ',
'फऌँ',
'फऌं',
'फऌः',
'फऌऺ',
'फऌऻ',
'फऌ़',
'फऌा',
'फऌि',
'फऌी',
'फऌु',
'फऌू',
'फऌृ',
'फऌॄ',
'फऌॅ',
'फऌॆ',
'फऌे',
'फऌै',
'फऌॉ',
'फऌॊ',
'फऌो',
'फऌौ',
'फऌ्',
'फऌॎ',
'फऌॏ',
'फऌॢ',
'फऌॣ',
'फए',
'फएऀ',
'फएँ',
'फएं',
'फएः',
'फएऺ',
'फएऻ',
'फए़',
'फएा',
'फएि',
'फएी',
'फएु',
'फएू',
'फएृ',
'फएॄ',
'फएॅ',
'फएॆ',
'फएे',
'फएै',
'फएॉ',
'फएॊ',
'फएो',
'फएौ',
'फए्',
'फएॎ',
'फएॏ',
'फएॢ',
'फएॣ',
'फऐ',
'फऐऀ',
'फऐँ',
'फऐं',
'फऐः',
'फऐऺ',
'फऐऻ',
'फऐ़',
'फऐा',
'फऐि',
'फऐी',
'फऐु',
'फऐू',
'फऐृ',
'फऐॄ',
'फऐॅ',
'फऐॆ',
'फऐे',
'फऐै',
'फऐॉ',
'फऐॊ',
'फऐो',
'फऐौ',
'फऐ्',
'फऐॎ',
'फऐॏ',
'फऐॢ',
'फऐॣ',
'फओ',
'फओऀ',
'फओँ',
'फओं',
'फओः',
'फओऺ',
'फओऻ',
'फओ़',
'फओा',
'फओि',
'फओी',
'फओु',
'फओू',
'फओृ',
'फओॄ',
'फओॅ',
'फओॆ',
'फओे',
'फओै',
'फओॉ',
'फओॊ',
'फओो',
'फओौ',
'फओ्',
'फओॎ',
'फओॏ',
'फओॢ',
'फओॣ',
'फऔ',
'फऔऀ',
'फऔँ',
'फऔं',
'फऔः',
'फऔऺ',
'फऔऻ',
'फऔ़',
'फऔा',
'फऔि',
'फऔी',
'फऔु',
'फऔू',
'फऔृ',
'फऔॄ',
'फऔॅ',
'फऔॆ',
'फऔे',
'फऔै',
'फऔॉ',
'फऔॊ',
'फऔो',
'फऔौ',
'फऔ्',
'फऔॎ',
'फऔॏ',
'फऔॢ',
'फऔॣ',
'बअ',
'बअऀ',
'बअँ',
'बअं',
'बअः',
'बअऺ',
'बअऻ',
'बअ़',
'बअा',
'बअि',
'बअी',
'बअु',
'बअू',
'बअृ',
'बअॄ',
'बअॅ',
'बअॆ',
'बअे',
'बअै',
'बअॉ',
'बअॊ',
'बअो',
'बअौ',
'बअ्',
'बअॎ',
'बअॏ',
'बअॢ',
'बअॣ',
'बइ',
'बइऀ',
'बइँ',
'बइं',
'बइः',
'बइऺ',
'बइऻ',
'बइ़',
'बइा',
'बइि',
'बइी',
'बइु',
'बइू',
'बइृ',
'बइॄ',
'बइॅ',
'बइॆ',
'बइे',
'बइै',
'बइॉ',
'बइॊ',
'बइो',
'बइौ',
'बइ्',
'बइॎ',
'बइॏ',
'बइॢ',
'बइॣ',
'बउ',
'बउऀ',
'बउँ',
'बउं',
'बउः',
'बउऺ',
'बउऻ',
'बउ़',
'बउा',
'बउि',
'बउी',
'बउु',
'बउू',
'बउृ',
'बउॄ',
'बउॅ',
'बउॆ',
'बउे',
'बउै',
'बउॉ',
'बउॊ',
'बउो',
'बउौ',
'बउ्',
'बउॎ',
'बउॏ',
'बउॢ',
'बउॣ',
'बऋ',
'बऋऀ',
'बऋँ',
'बऋं',
'बऋः',
'बऋऺ',
'बऋऻ',
'बऋ़',
'बऋा',
'बऋि',
'बऋी',
'बऋु',
'बऋू',
'बऋृ',
'बऋॄ',
'बऋॅ',
'बऋॆ',
'बऋे',
'बऋै',
'बऋॉ',
'बऋॊ',
'बऋो',
'बऋौ',
'बऋ्',
'बऋॎ',
'बऋॏ',
'बऋॢ',
'बऋॣ',
'बऌ',
'बऌऀ',
'बऌँ',
'बऌं',
'बऌः',
'बऌऺ',
'बऌऻ',
'बऌ़',
'बऌा',
'बऌि',
'बऌी',
'बऌु',
'बऌू',
'बऌृ',
'बऌॄ',
'बऌॅ',
'बऌॆ',
'बऌे',
'बऌै',
'बऌॉ',
'बऌॊ',
'बऌो',
'बऌौ',
'बऌ्',
'बऌॎ',
'बऌॏ',
'बऌॢ',
'बऌॣ',
'बए',
'बएऀ',
'बएँ',
'बएं',
'बएः',
'बएऺ',
'बएऻ',
'बए़',
'बएा',
'बएि',
'बएी',
'बएु',
'बएू',
'बएृ',
'बएॄ',
'बएॅ',
'बएॆ',
'बएे',
'बएै',
'बएॉ',
'बएॊ',
'बएो',
'बएौ',
'बए्',
'बएॎ',
'बएॏ',
'बएॢ',
'बएॣ',
'बऐ',
'बऐऀ',
'बऐँ',
'बऐं',
'बऐः',
'बऐऺ',
'बऐऻ',
'बऐ़',
'बऐा',
'बऐि',
'बऐी',
'बऐु',
'बऐू',
'बऐृ',
'बऐॄ',
'बऐॅ',
'बऐॆ',
'बऐे',
'बऐै',
'बऐॉ',
'बऐॊ',
'बऐो',
'बऐौ',
'बऐ्',
'बऐॎ',
'बऐॏ',
'बऐॢ',
'बऐॣ',
'बओ',
'बओऀ',
'बओँ',
'बओं',
'बओः',
'बओऺ',
'बओऻ',
'बओ़',
'बओा',
'बओि',
'बओी',
'बओु',
'बओू',
'बओृ',
'बओॄ',
'बओॅ',
'बओॆ',
'बओे',
'बओै',
'बओॉ',
'बओॊ',
'बओो',
'बओौ',
'बओ्',
'बओॎ',
'बओॏ',
'बओॢ',
'बओॣ',
'बऔ',
'बऔऀ',
'बऔँ',
'बऔं',
'बऔः',
'बऔऺ',
'बऔऻ',
'बऔ़',
'बऔा',
'बऔि',
'बऔी',
'बऔु',
'बऔू',
'बऔृ',
'बऔॄ',
'बऔॅ',
'बऔॆ',
'बऔे',
'बऔै',
'बऔॉ',
'बऔॊ',
'बऔो',
'बऔौ',
'बऔ्',
'बऔॎ',
'बऔॏ',
'बऔॢ',
'बऔॣ',
'भअ',
'भअऀ',
'भअँ',
'भअं',
'भअः',
'भअऺ',
'भअऻ',
'भअ़',
'भअा',
'भअि',
'भअी',
'भअु',
'भअू',
'भअृ',
'भअॄ',
'भअॅ',
'भअॆ',
'भअे',
'भअै',
'भअॉ',
'भअॊ',
'भअो',
'भअौ',
'भअ्',
'भअॎ',
'भअॏ',
'भअॢ',
'भअॣ',
'भइ',
'भइऀ',
'भइँ',
'भइं',
'भइः',
'भइऺ',
'भइऻ',
'भइ़',
'भइा',
'भइि',
'भइी',
'भइु',
'भइू',
'भइृ',
'भइॄ',
'भइॅ',
'भइॆ',
'भइे',
'भइै',
'भइॉ',
'भइॊ',
'भइो',
'भइौ',
'भइ्',
'भइॎ',
'भइॏ',
'भइॢ',
'भइॣ',
'भउ',
'भउऀ',
'भउँ',
'भउं',
'भउः',
'भउऺ',
'भउऻ',
'भउ़',
'भउा',
'भउि',
'भउी',
'भउु',
'भउू',
'भउृ',
'भउॄ',
'भउॅ',
'भउॆ',
'भउे',
'भउै',
'भउॉ',
'भउॊ',
'भउो',
'भउौ',
'भउ्',
'भउॎ',
'भउॏ',
'भउॢ',
'भउॣ',
'भऋ',
'भऋऀ',
'भऋँ',
'भऋं',
'भऋः',
'भऋऺ',
'भऋऻ',
'भऋ़',
'भऋा',
'भऋि',
'भऋी',
'भऋु',
'भऋू',
'भऋृ',
'भऋॄ',
'भऋॅ',
'भऋॆ',
'भऋे',
'भऋै',
'भऋॉ',
'भऋॊ',
'भऋो',
'भऋौ',
'भऋ्',
'भऋॎ',
'भऋॏ',
'भऋॢ',
'भऋॣ',
'भऌ',
'भऌऀ',
'भऌँ',
'भऌं',
'भऌः',
'भऌऺ',
'भऌऻ',
'भऌ़',
'भऌा',
'भऌि',
'भऌी',
'भऌु',
'भऌू',
'भऌृ',
'भऌॄ',
'भऌॅ',
'भऌॆ',
'भऌे',
'भऌै',
'भऌॉ',
'भऌॊ',
'भऌो',
'भऌौ',
'भऌ्',
'भऌॎ',
'भऌॏ',
'भऌॢ',
'भऌॣ',
'भए',
'भएऀ',
'भएँ',
'भएं',
'भएः',
'भएऺ',
'भएऻ',
'भए़',
'भएा',
'भएि',
'भएी',
'भएु',
'भएू',
'भएृ',
'भएॄ',
'भएॅ',
'भएॆ',
'भएे',
'भएै',
'भएॉ',
'भएॊ',
'भएो',
'भएौ',
'भए्',
'भएॎ',
'भएॏ',
'भएॢ',
'भएॣ',
'भऐ',
'भऐऀ',
'भऐँ',
'भऐं',
'भऐः',
'भऐऺ',
'भऐऻ',
'भऐ़',
'भऐा',
'भऐि',
'भऐी',
'भऐु',
'भऐू',
'भऐृ',
'भऐॄ',
'भऐॅ',
'भऐॆ',
'भऐे',
'भऐै',
'भऐॉ',
'भऐॊ',
'भऐो',
'भऐौ',
'भऐ्',
'भऐॎ',
'भऐॏ',
'भऐॢ',
'भऐॣ',
'भओ',
'भओऀ',
'भओँ',
'भओं',
'भओः',
'भओऺ',
'भओऻ',
'भओ़',
'भओा',
'भओि',
'भओी',
'भओु',
'भओू',
'भओृ',
'भओॄ',
'भओॅ',
'भओॆ',
'भओे',
'भओै',
'भओॉ',
'भओॊ',
'भओो',
'भओौ',
'भओ्',
'भओॎ',
'भओॏ',
'भओॢ',
'भओॣ',
'भऔ',
'भऔऀ',
'भऔँ',
'भऔं',
'भऔः',
'भऔऺ',
'भऔऻ',
'भऔ़',
'भऔा',
'भऔि',
'भऔी',
'भऔु',
'भऔू',
'भऔृ',
'भऔॄ',
'भऔॅ',
'भऔॆ',
'भऔे',
'भऔै',
'भऔॉ',
'भऔॊ',
'भऔो',
'भऔौ',
'भऔ्',
'भऔॎ',
'भऔॏ',
'भऔॢ',
'भऔॣ',
'मअ',
'मअऀ',
'मअँ',
'मअं',
'मअः',
'मअऺ',
'मअऻ',
'मअ़',
'मअा',
'मअि',
'मअी',
'मअु',
'मअू',
'मअृ',
'मअॄ',
'मअॅ',
'मअॆ',
'मअे',
'मअै',
'मअॉ',
'मअॊ',
'मअो',
'मअौ',
'मअ्',
'मअॎ',
'मअॏ',
'मअॢ',
'मअॣ',
'मइ',
'मइऀ',
'मइँ',
'मइं',
'मइः',
'मइऺ',
'मइऻ',
'मइ़',
'मइा',
'मइि',
'मइी',
'मइु',
'मइू',
'मइृ',
'मइॄ',
'मइॅ',
'मइॆ',
'मइे',
'मइै',
'मइॉ',
'मइॊ',
'मइो',
'मइौ',
'मइ्',
'मइॎ',
'मइॏ',
'मइॢ',
'मइॣ',
'मउ',
'मउऀ',
'मउँ',
'मउं',
'मउः',
'मउऺ',
'मउऻ',
'मउ़',
'मउा',
'मउि',
'मउी',
'मउु',
'मउू',
'मउृ',
'मउॄ',
'मउॅ',
'मउॆ',
'मउे',
'मउै',
'मउॉ',
'मउॊ',
'मउो',
'मउौ',
'मउ्',
'मउॎ',
'मउॏ',
'मउॢ',
'मउॣ',
'मऋ',
'मऋऀ',
'मऋँ',
'मऋं',
'मऋः',
'मऋऺ',
'मऋऻ',
'मऋ़',
'मऋा',
'मऋि',
'मऋी',
'मऋु',
'मऋू',
'मऋृ',
'मऋॄ',
'मऋॅ',
'मऋॆ',
'मऋे',
'मऋै',
'मऋॉ',
'मऋॊ',
'मऋो',
'मऋौ',
'मऋ्',
'मऋॎ',
'मऋॏ',
'मऋॢ',
'मऋॣ',
'मऌ',
'मऌऀ',
'मऌँ',
'मऌं',
'मऌः',
'मऌऺ',
'मऌऻ',
'मऌ़',
'मऌा',
'मऌि',
'मऌी',
'मऌु',
'मऌू',
'मऌृ',
'मऌॄ',
'मऌॅ',
'मऌॆ',
'मऌे',
'मऌै',
'मऌॉ',
'मऌॊ',
'मऌो',
'मऌौ',
'मऌ्',
'मऌॎ',
'मऌॏ',
'मऌॢ',
'मऌॣ',
'मए',
'मएऀ',
'मएँ',
'मएं',
'मएः',
'मएऺ',
'मएऻ',
'मए़',
'मएा',
'मएि',
'मएी',
'मएु',
'मएू',
'मएृ',
'मएॄ',
'मएॅ',
'मएॆ',
'मएे',
'मएै',
'मएॉ',
'मएॊ',
'मएो',
'मएौ',
'मए्',
'मएॎ',
'मएॏ',
'मएॢ',
'मएॣ',
'मऐ',
'मऐऀ',
'मऐँ',
'मऐं',
'मऐः',
'मऐऺ',
'मऐऻ',
'मऐ़',
'मऐा',
'मऐि',
'मऐी',
'मऐु',
'मऐू',
'मऐृ',
'मऐॄ',
'मऐॅ',
'मऐॆ',
'मऐे',
'मऐै',
'मऐॉ',
'मऐॊ',
'मऐो',
'मऐौ',
'मऐ्',
'मऐॎ',
'मऐॏ',
'मऐॢ',
'मऐॣ',
'मओ',
'मओऀ',
'मओँ',
'मओं',
'मओः',
'मओऺ',
'मओऻ',
'मओ़',
'मओा',
'मओि',
'मओी',
'मओु',
'मओू',
'मओृ',
'मओॄ',
'मओॅ',
'मओॆ',
'मओे',
'मओै',
'मओॉ',
'मओॊ',
'मओो',
'मओौ',
'मओ्',
'मओॎ',
'मओॏ',
'मओॢ',
'मओॣ',
'मऔ',
'मऔऀ',
'मऔँ',
'मऔं',
'मऔः',
'मऔऺ',
'मऔऻ',
'मऔ़',
'मऔा',
'मऔि',
'मऔी',
'मऔु',
'मऔू',
'मऔृ',
'मऔॄ',
'मऔॅ',
'मऔॆ',
'मऔे',
'मऔै',
'मऔॉ',
'मऔॊ',
'मऔो',
'मऔौ',
'मऔ्',
'मऔॎ',
'मऔॏ',
'मऔॢ',
'मऔॣ',
'यअ',
'यअऀ',
'यअँ',
'यअं',
'यअः',
'यअऺ',
'यअऻ',
'यअ़',
'यअा',
'यअि',
'यअी',
'यअु',
'यअू',
'यअृ',
'यअॄ',
'यअॅ',
'यअॆ',
'यअे',
'यअै',
'यअॉ',
'यअॊ',
'यअो',
'यअौ',
'यअ्',
'यअॎ',
'यअॏ',
'यअॢ',
'यअॣ',
'यइ',
'यइऀ',
'यइँ',
'यइं',
'यइः',
'यइऺ',
'यइऻ',
'यइ़',
'यइा',
'यइि',
'यइी',
'यइु',
'यइू',
'यइृ',
'यइॄ',
'यइॅ',
'यइॆ',
'यइे',
'यइै',
'यइॉ',
'यइॊ',
'यइो',
'यइौ',
'यइ्',
'यइॎ',
'यइॏ',
'यइॢ',
'यइॣ',
'यउ',
'यउऀ',
'यउँ',
'यउं',
'यउः',
'यउऺ',
'यउऻ',
'यउ़',
'यउा',
'यउि',
'यउी',
'यउु',
'यउू',
'यउृ',
'यउॄ',
'यउॅ',
'यउॆ',
'यउे',
'यउै',
'यउॉ',
'यउॊ',
'यउो',
'यउौ',
'यउ्',
'यउॎ',
'यउॏ',
'यउॢ',
'यउॣ',
'यऋ',
'यऋऀ',
'यऋँ',
'यऋं',
'यऋः',
'यऋऺ',
'यऋऻ',
'यऋ़',
'यऋा',
'यऋि',
'यऋी',
'यऋु',
'यऋू',
'यऋृ',
'यऋॄ',
'यऋॅ',
'यऋॆ',
'यऋे',
'यऋै',
'यऋॉ',
'यऋॊ',
'यऋो',
'यऋौ',
'यऋ्',
'यऋॎ',
'यऋॏ',
'यऋॢ',
'यऋॣ',
'यऌ',
'यऌऀ',
'यऌँ',
'यऌं',
'यऌः',
'यऌऺ',
'यऌऻ',
'यऌ़',
'यऌा',
'यऌि',
'यऌी',
'यऌु',
'यऌू',
'यऌृ',
'यऌॄ',
'यऌॅ',
'यऌॆ',
'यऌे',
'यऌै',
'यऌॉ',
'यऌॊ',
'यऌो',
'यऌौ',
'यऌ्',
'यऌॎ',
'यऌॏ',
'यऌॢ',
'यऌॣ',
'यए',
'यएऀ',
'यएँ',
'यएं',
'यएः',
'यएऺ',
'यएऻ',
'यए़',
'यएा',
'यएि',
'यएी',
'यएु',
'यएू',
'यएृ',
'यएॄ',
'यएॅ',
'यएॆ',
'यएे',
'यएै',
'यएॉ',
'यएॊ',
'यएो',
'यएौ',
'यए्',
'यएॎ',
'यएॏ',
'यएॢ',
'यएॣ',
'यऐ',
'यऐऀ',
'यऐँ',
'यऐं',
'यऐः',
'यऐऺ',
'यऐऻ',
'यऐ़',
'यऐा',
'यऐि',
'यऐी',
'यऐु',
'यऐू',
'यऐृ',
'यऐॄ',
'यऐॅ',
'यऐॆ',
'यऐे',
'यऐै',
'यऐॉ',
'यऐॊ',
'यऐो',
'यऐौ',
'यऐ्',
'यऐॎ',
'यऐॏ',
'यऐॢ',
'यऐॣ',
'यओ',
'यओऀ',
'यओँ',
'यओं',
'यओः',
'यओऺ',
'यओऻ',
'यओ़',
'यओा',
'यओि',
'यओी',
'यओु',
'यओू',
'यओृ',
'यओॄ',
'यओॅ',
'यओॆ',
'यओे',
'यओै',
'यओॉ',
'यओॊ',
'यओो',
'यओौ',
'यओ्',
'यओॎ',
'यओॏ',
'यओॢ',
'यओॣ',
'यऔ',
'यऔऀ',
'यऔँ',
'यऔं',
'यऔः',
'यऔऺ',
'यऔऻ',
'यऔ़',
'यऔा',
'यऔि',
'यऔी',
'यऔु',
'यऔू',
'यऔृ',
'यऔॄ',
'यऔॅ',
'यऔॆ',
'यऔे',
'यऔै',
'यऔॉ',
'यऔॊ',
'यऔो',
'यऔौ',
'यऔ्',
'यऔॎ',
'यऔॏ',
'यऔॢ',
'यऔॣ',
'रअ',
'रअऀ',
'रअँ',
'रअं',
'रअः',
'रअऺ',
'रअऻ',
'रअ़',
'रअा',
'रअि',
'रअी',
'रअु',
'रअू',
'रअृ',
'रअॄ',
'रअॅ',
'रअॆ',
'रअे',
'रअै',
'रअॉ',
'रअॊ',
'रअो',
'रअौ',
'रअ्',
'रअॎ',
'रअॏ',
'रअॢ',
'रअॣ',
'रइ',
'रइऀ',
'रइँ',
'रइं',
'रइः',
'रइऺ',
'रइऻ',
'रइ़',
'रइा',
'रइि',
'रइी',
'रइु',
'रइू',
'रइृ',
'रइॄ',
'रइॅ',
'रइॆ',
'रइे',
'रइै',
'रइॉ',
'रइॊ',
'रइो',
'रइौ',
'रइ्',
'रइॎ',
'रइॏ',
'रइॢ',
'रइॣ',
'रउ',
'रउऀ',
'रउँ',
'रउं',
'रउः',
'रउऺ',
'रउऻ',
'रउ़',
'रउा',
'रउि',
'रउी',
'रउु',
'रउू',
'रउृ',
'रउॄ',
'रउॅ',
'रउॆ',
'रउे',
'रउै',
'रउॉ',
'रउॊ',
'रउो',
'रउौ',
'रउ्',
'रउॎ',
'रउॏ',
'रउॢ',
'रउॣ',
'रऋ',
'रऋऀ',
'रऋँ',
'रऋं',
'रऋः',
'रऋऺ',
'रऋऻ',
'रऋ़',
'रऋा',
'रऋि',
'रऋी',
'रऋु',
'रऋू',
'रऋृ',
'रऋॄ',
'रऋॅ',
'रऋॆ',
'रऋे',
'रऋै',
'रऋॉ',
'रऋॊ',
'रऋो',
'रऋौ',
'रऋ्',
'रऋॎ',
'रऋॏ',
'रऋॢ',
'रऋॣ',
'रऌ',
'रऌऀ',
'रऌँ',
'रऌं',
'रऌः',
'रऌऺ',
'रऌऻ',
'रऌ़',
'रऌा',
'रऌि',
'रऌी',
'रऌु',
'रऌू',
'रऌृ',
'रऌॄ',
'रऌॅ',
'रऌॆ',
'रऌे',
'रऌै',
'रऌॉ',
'रऌॊ',
'रऌो',
'रऌौ',
'रऌ्',
'रऌॎ',
'रऌॏ',
'रऌॢ',
'रऌॣ',
'रए',
'रएऀ',
'रएँ',
'रएं',
'रएः',
'रएऺ',
'रएऻ',
'रए़',
'रएा',
'रएि',
'रएी',
'रएु',
'रएू',
'रएृ',
'रएॄ',
'रएॅ',
'रएॆ',
'रएे',
'रएै',
'रएॉ',
'रएॊ',
'रएो',
'रएौ',
'रए्',
'रएॎ',
'रएॏ',
'रएॢ',
'रएॣ',
'रऐ',
'रऐऀ',
'रऐँ',
'रऐं',
'रऐः',
'रऐऺ',
'रऐऻ',
'रऐ़',
'रऐा',
'रऐि',
'रऐी',
'रऐु',
'रऐू',
'रऐृ',
'रऐॄ',
'रऐॅ',
'रऐॆ',
'रऐे',
'रऐै',
'रऐॉ',
'रऐॊ',
'रऐो',
'रऐौ',
'रऐ्',
'रऐॎ',
'रऐॏ',
'रऐॢ',
'रऐॣ',
'रओ',
'रओऀ',
'रओँ',
'रओं',
'रओः',
'रओऺ',
'रओऻ',
'रओ़',
'रओा',
'रओि',
'रओी',
'रओु',
'रओू',
'रओृ',
'रओॄ',
'रओॅ',
'रओॆ',
'रओे',
'रओै',
'रओॉ',
'रओॊ',
'रओो',
'रओौ',
'रओ्',
'रओॎ',
'रओॏ',
'रओॢ',
'रओॣ',
'रऔ',
'रऔऀ',
'रऔँ',
'रऔं',
'रऔः',
'रऔऺ',
'रऔऻ',
'रऔ़',
'रऔा',
'रऔि',
'रऔी',
'रऔु',
'रऔू',
'रऔृ',
'रऔॄ',
'रऔॅ',
'रऔॆ',
'रऔे',
'रऔै',
'रऔॉ',
'रऔॊ',
'रऔो',
'रऔौ',
'रऔ्',
'रऔॎ',
'रऔॏ',
'रऔॢ',
'रऔॣ',
'लअ',
'लअऀ',
'लअँ',
'लअं',
'लअः',
'लअऺ',
'लअऻ',
'लअ़',
'लअा',
'लअि',
'लअी',
'लअु',
'लअू',
'लअृ',
'लअॄ',
'लअॅ',
'लअॆ',
'लअे',
'लअै',
'लअॉ',
'लअॊ',
'लअो',
'लअौ',
'लअ्',
'लअॎ',
'लअॏ',
'लअॢ',
'लअॣ',
'लइ',
'लइऀ',
'लइँ',
'लइं',
'लइः',
'लइऺ',
'लइऻ',
'लइ़',
'लइा',
'लइि',
'लइी',
'लइु',
'लइू',
'लइृ',
'लइॄ',
'लइॅ',
'लइॆ',
'लइे',
'लइै',
'लइॉ',
'लइॊ',
'लइो',
'लइौ',
'लइ्',
'लइॎ',
'लइॏ',
'लइॢ',
'लइॣ',
'लउ',
'लउऀ',
'लउँ',
'लउं',
'लउः',
'लउऺ',
'लउऻ',
'लउ़',
'लउा',
'लउि',
'लउी',
'लउु',
'लउू',
'लउृ',
'लउॄ',
'लउॅ',
'लउॆ',
'लउे',
'लउै',
'लउॉ',
'लउॊ',
'लउो',
'लउौ',
'लउ्',
'लउॎ',
'लउॏ',
'लउॢ',
'लउॣ',
'लऋ',
'लऋऀ',
'लऋँ',
'लऋं',
'लऋः',
'लऋऺ',
'लऋऻ',
'लऋ़',
'लऋा',
'लऋि',
'लऋी',
'लऋु',
'लऋू',
'लऋृ',
'लऋॄ',
'लऋॅ',
'लऋॆ',
'लऋे',
'लऋै',
'लऋॉ',
'लऋॊ',
'लऋो',
'लऋौ',
'लऋ्',
'लऋॎ',
'लऋॏ',
'लऋॢ',
'लऋॣ',
'लऌ',
'लऌऀ',
'लऌँ',
'लऌं',
'लऌः',
'लऌऺ',
'लऌऻ',
'लऌ़',
'लऌा',
'लऌि',
'लऌी',
'लऌु',
'लऌू',
'लऌृ',
'लऌॄ',
'लऌॅ',
'लऌॆ',
'लऌे',
'लऌै',
'लऌॉ',
'लऌॊ',
'लऌो',
'लऌौ',
'लऌ्',
'लऌॎ',
'लऌॏ',
'लऌॢ',
'लऌॣ',
'लए',
'लएऀ',
'लएँ',
'लएं',
'लएः',
'लएऺ',
'लएऻ',
'लए़',
'लएा',
'लएि',
'लएी',
'लएु',
'लएू',
'लएृ',
'लएॄ',
'लएॅ',
'लएॆ',
'लएे',
'लएै',
'लएॉ',
'लएॊ',
'लएो',
'लएौ',
'लए्',
'लएॎ',
'लएॏ',
'लएॢ',
'लएॣ',
'लऐ',
'लऐऀ',
'लऐँ',
'लऐं',
'लऐः',
'लऐऺ',
'लऐऻ',
'लऐ़',
'लऐा',
'लऐि',
'लऐी',
'लऐु',
'लऐू',
'लऐृ',
'लऐॄ',
'लऐॅ',
'लऐॆ',
'लऐे',
'लऐै',
'लऐॉ',
'लऐॊ',
'लऐो',
'लऐौ',
'लऐ्',
'लऐॎ',
'लऐॏ',
'लऐॢ',
'लऐॣ',
'लओ',
'लओऀ',
'लओँ',
'लओं',
'लओः',
'लओऺ',
'लओऻ',
'लओ़',
'लओा',
'लओि',
'लओी',
'लओु',
'लओू',
'लओृ',
'लओॄ',
'लओॅ',
'लओॆ',
'लओे',
'लओै',
'लओॉ',
'लओॊ',
'लओो',
'लओौ',
'लओ्',
'लओॎ',
'लओॏ',
'लओॢ',
'लओॣ',
'लऔ',
'लऔऀ',
'लऔँ',
'लऔं',
'लऔः',
'लऔऺ',
'लऔऻ',
'लऔ़',
'लऔा',
'लऔि',
'लऔी',
'लऔु',
'लऔू',
'लऔृ',
'लऔॄ',
'लऔॅ',
'लऔॆ',
'लऔे',
'लऔै',
'लऔॉ',
'लऔॊ',
'लऔो',
'लऔौ',
'लऔ्',
'लऔॎ',
'लऔॏ',
'लऔॢ',
'लऔॣ',
'वअ',
'वअऀ',
'वअँ',
'वअं',
'वअः',
'वअऺ',
'वअऻ',
'वअ़',
'वअा',
'वअि',
'वअी',
'वअु',
'वअू',
'वअृ',
'वअॄ',
'वअॅ',
'वअॆ',
'वअे',
'वअै',
'वअॉ',
'वअॊ',
'वअो',
'वअौ',
'वअ्',
'वअॎ',
'वअॏ',
'वअॢ',
'वअॣ',
'वइ',
'वइऀ',
'वइँ',
'वइं',
'वइः',
'वइऺ',
'वइऻ',
'वइ़',
'वइा',
'वइि',
'वइी',
'वइु',
'वइू',
'वइृ',
'वइॄ',
'वइॅ',
'वइॆ',
'वइे',
'वइै',
'वइॉ',
'वइॊ',
'वइो',
'वइौ',
'वइ्',
'वइॎ',
'वइॏ',
'वइॢ',
'वइॣ',
'वउ',
'वउऀ',
'वउँ',
'वउं',
'वउः',
'वउऺ',
'वउऻ',
'वउ़',
'वउा',
'वउि',
'वउी',
'वउु',
'वउू',
'वउृ',
'वउॄ',
'वउॅ',
'वउॆ',
'वउे',
'वउै',
'वउॉ',
'वउॊ',
'वउो',
'वउौ',
'वउ्',
'वउॎ',
'वउॏ',
'वउॢ',
'वउॣ',
'वऋ',
'वऋऀ',
'वऋँ',
'वऋं',
'वऋः',
'वऋऺ',
'वऋऻ',
'वऋ़',
'वऋा',
'वऋि',
'वऋी',
'वऋु',
'वऋू',
'वऋृ',
'वऋॄ',
'वऋॅ',
'वऋॆ',
'वऋे',
'वऋै',
'वऋॉ',
'वऋॊ',
'वऋो',
'वऋौ',
'वऋ्',
'वऋॎ',
'वऋॏ',
'वऋॢ',
'वऋॣ',
'वऌ',
'वऌऀ',
'वऌँ',
'वऌं',
'वऌः',
'वऌऺ',
'वऌऻ',
'वऌ़',
'वऌा',
'वऌि',
'वऌी',
'वऌु',
'वऌू',
'वऌृ',
'वऌॄ',
'वऌॅ',
'वऌॆ',
'वऌे',
'वऌै',
'वऌॉ',
'वऌॊ',
'वऌो',
'वऌौ',
'वऌ्',
'वऌॎ',
'वऌॏ',
'वऌॢ',
'वऌॣ',
'वए',
'वएऀ',
'वएँ',
'वएं',
'वएः',
'वएऺ',
'वएऻ',
'वए़',
'वएा',
'वएि',
'वएी',
'वएु',
'वएू',
'वएृ',
'वएॄ',
'वएॅ',
'वएॆ',
'वएे',
'वएै',
'वएॉ',
'वएॊ',
'वएो',
'वएौ',
'वए्',
'वएॎ',
'वएॏ',
'वएॢ',
'वएॣ',
'वऐ',
'वऐऀ',
'वऐँ',
'वऐं',
'वऐः',
'वऐऺ',
'वऐऻ',
'वऐ़',
'वऐा',
'वऐि',
'वऐी',
'वऐु',
'वऐू',
'वऐृ',
'वऐॄ',
'वऐॅ',
'वऐॆ',
'वऐे',
'वऐै',
'वऐॉ',
'वऐॊ',
'वऐो',
'वऐौ',
'वऐ्',
'वऐॎ',
'वऐॏ',
'वऐॢ',
'वऐॣ',
'वओ',
'वओऀ',
'वओँ',
'वओं',
'वओः',
'वओऺ',
'वओऻ',
'वओ़',
'वओा',
'वओि',
'वओी',
'वओु',
'वओू',
'वओृ',
'वओॄ',
'वओॅ',
'वओॆ',
'वओे',
'वओै',
'वओॉ',
'वओॊ',
'वओो',
'वओौ',
'वओ्',
'वओॎ',
'वओॏ',
'वओॢ',
'वओॣ',
'वऔ',
'वऔऀ',
'वऔँ',
'वऔं',
'वऔः',
'वऔऺ',
'वऔऻ',
'वऔ़',
'वऔा',
'वऔि',
'वऔी',
'वऔु',
'वऔू',
'वऔृ',
'वऔॄ',
'वऔॅ',
'वऔॆ',
'वऔे',
'वऔै',
'वऔॉ',
'वऔॊ',
'वऔो',
'वऔौ',
'वऔ्',
'वऔॎ',
'वऔॏ',
'वऔॢ',
'वऔॣ',
'शअ',
'शअऀ',
'शअँ',
'शअं',
'शअः',
'शअऺ',
'शअऻ',
'शअ़',
'शअा',
'शअि',
'शअी',
'शअु',
'शअू',
'शअृ',
'शअॄ',
'शअॅ',
'शअॆ',
'शअे',
'शअै',
'शअॉ',
'शअॊ',
'शअो',
'शअौ',
'शअ्',
'शअॎ',
'शअॏ',
'शअॢ',
'शअॣ',
'शइ',
'शइऀ',
'शइँ',
'शइं',
'शइः',
'शइऺ',
'शइऻ',
'शइ़',
'शइा',
'शइि',
'शइी',
'शइु',
'शइू',
'शइृ',
'शइॄ',
'शइॅ',
'शइॆ',
'शइे',
'शइै',
'शइॉ',
'शइॊ',
'शइो',
'शइौ',
'शइ्',
'शइॎ',
'शइॏ',
'शइॢ',
'शइॣ',
'शउ',
'शउऀ',
'शउँ',
'शउं',
'शउः',
'शउऺ',
'शउऻ',
'शउ़',
'शउा',
'शउि',
'शउी',
'शउु',
'शउू',
'शउृ',
'शउॄ',
'शउॅ',
'शउॆ',
'शउे',
'शउै',
'शउॉ',
'शउॊ',
'शउो',
'शउौ',
'शउ्',
'शउॎ',
'शउॏ',
'शउॢ',
'शउॣ',
'शऋ',
'शऋऀ',
'शऋँ',
'शऋं',
'शऋः',
'शऋऺ',
'शऋऻ',
'शऋ़',
'शऋा',
'शऋि',
'शऋी',
'शऋु',
'शऋू',
'शऋृ',
'शऋॄ',
'शऋॅ',
'शऋॆ',
'शऋे',
'शऋै',
'शऋॉ',
'शऋॊ',
'शऋो',
'शऋौ',
'शऋ्',
'शऋॎ',
'शऋॏ',
'शऋॢ',
'शऋॣ',
'शऌ',
'शऌऀ',
'शऌँ',
'शऌं',
'शऌः',
'शऌऺ',
'शऌऻ',
'शऌ़',
'शऌा',
'शऌि',
'शऌी',
'शऌु',
'शऌू',
'शऌृ',
'शऌॄ',
'शऌॅ',
'शऌॆ',
'शऌे',
'शऌै',
'शऌॉ',
'शऌॊ',
'शऌो',
'शऌौ',
'शऌ्',
'शऌॎ',
'शऌॏ',
'शऌॢ',
'शऌॣ',
'शए',
'शएऀ',
'शएँ',
'शएं',
'शएः',
'शएऺ',
'शएऻ',
'शए़',
'शएा',
'शएि',
'शएी',
'शएु',
'शएू',
'शएृ',
'शएॄ',
'शएॅ',
'शएॆ',
'शएे',
'शएै',
'शएॉ',
'शएॊ',
'शएो',
'शएौ',
'शए्',
'शएॎ',
'शएॏ',
'शएॢ',
'शएॣ',
'शऐ',
'शऐऀ',
'शऐँ',
'शऐं',
'शऐः',
'शऐऺ',
'शऐऻ',
'शऐ़',
'शऐा',
'शऐि',
'शऐी',
'शऐु',
'शऐू',
'शऐृ',
'शऐॄ',
'शऐॅ',
'शऐॆ',
'शऐे',
'शऐै',
'शऐॉ',
'शऐॊ',
'शऐो',
'शऐौ',
'शऐ्',
'शऐॎ',
'शऐॏ',
'शऐॢ',
'शऐॣ',
'शओ',
'शओऀ',
'शओँ',
'शओं',
'शओः',
'शओऺ',
'शओऻ',
'शओ़',
'शओा',
'शओि',
'शओी',
'शओु',
'शओू',
'शओृ',
'शओॄ',
'शओॅ',
'शओॆ',
'शओे',
'शओै',
'शओॉ',
'शओॊ',
'शओो',
'शओौ',
'शओ्',
'शओॎ',
'शओॏ',
'शओॢ',
'शओॣ',
'शऔ',
'शऔऀ',
'शऔँ',
'शऔं',
'शऔः',
'शऔऺ',
'शऔऻ',
'शऔ़',
'शऔा',
'शऔि',
'शऔी',
'शऔु',
'शऔू',
'शऔृ',
'शऔॄ',
'शऔॅ',
'शऔॆ',
'शऔे',
'शऔै',
'शऔॉ',
'शऔॊ',
'शऔो',
'शऔौ',
'शऔ्',
'शऔॎ',
'शऔॏ',
'शऔॢ',
'शऔॣ',
'षअ',
'षअऀ',
'षअँ',
'षअं',
'षअः',
'षअऺ',
'षअऻ',
'षअ़',
'षअा',
'षअि',
'षअी',
'षअु',
'षअू',
'षअृ',
'षअॄ',
'षअॅ',
'षअॆ',
'षअे',
'षअै',
'षअॉ',
'षअॊ',
'षअो',
'षअौ',
'षअ्',
'षअॎ',
'षअॏ',
'षअॢ',
'षअॣ',
'षइ',
'षइऀ',
'षइँ',
'षइं',
'षइः',
'षइऺ',
'षइऻ',
'षइ़',
'षइा',
'षइि',
'षइी',
'षइु',
'षइू',
'षइृ',
'षइॄ',
'षइॅ',
'षइॆ',
'षइे',
'षइै',
'षइॉ',
'षइॊ',
'षइो',
'षइौ',
'षइ्',
'षइॎ',
'षइॏ',
'षइॢ',
'षइॣ',
'षउ',
'षउऀ',
'षउँ',
'षउं',
'षउः',
'षउऺ',
'षउऻ',
'षउ़',
'षउा',
'षउि',
'षउी',
'षउु',
'षउू',
'षउृ',
'षउॄ',
'षउॅ',
'षउॆ',
'षउे',
'षउै',
'षउॉ',
'षउॊ',
'षउो',
'षउौ',
'षउ्',
'षउॎ',
'षउॏ',
'षउॢ',
'षउॣ',
'षऋ',
'षऋऀ',
'षऋँ',
'षऋं',
'षऋः',
'षऋऺ',
'षऋऻ',
'षऋ़',
'षऋा',
'षऋि',
'षऋी',
'षऋु',
'षऋू',
'षऋृ',
'षऋॄ',
'षऋॅ',
'षऋॆ',
'षऋे',
'षऋै',
'षऋॉ',
'षऋॊ',
'षऋो',
'षऋौ',
'षऋ्',
'षऋॎ',
'षऋॏ',
'षऋॢ',
'षऋॣ',
'षऌ',
'षऌऀ',
'षऌँ',
'षऌं',
'षऌः',
'षऌऺ',
'षऌऻ',
'षऌ़',
'षऌा',
'षऌि',
'षऌी',
'षऌु',
'षऌू',
'षऌृ',
'षऌॄ',
'षऌॅ',
'षऌॆ',
'षऌे',
'षऌै',
'षऌॉ',
'षऌॊ',
'षऌो',
'षऌौ',
'षऌ्',
'षऌॎ',
'षऌॏ',
'षऌॢ',
'षऌॣ',
'षए',
'षएऀ',
'षएँ',
'षएं',
'षएः',
'षएऺ',
'षएऻ',
'षए़',
'षएा',
'षएि',
'षएी',
'षएु',
'षएू',
'षएृ',
'षएॄ',
'षएॅ',
'षएॆ',
'षएे',
'षएै',
'षएॉ',
'षएॊ',
'षएो',
'षएौ',
'षए्',
'षएॎ',
'षएॏ',
'षएॢ',
'षएॣ',
'षऐ',
'षऐऀ',
'षऐँ',
'षऐं',
'षऐः',
'षऐऺ',
'षऐऻ',
'षऐ़',
'षऐा',
'षऐि',
'षऐी',
'षऐु',
'षऐू',
'षऐृ',
'षऐॄ',
'षऐॅ',
'षऐॆ',
'षऐे',
'षऐै',
'षऐॉ',
'षऐॊ',
'षऐो',
'षऐौ',
'षऐ्',
'षऐॎ',
'षऐॏ',
'षऐॢ',
'षऐॣ',
'षओ',
'षओऀ',
'षओँ',
'षओं',
'षओः',
'षओऺ',
'षओऻ',
'षओ़',
'षओा',
'षओि',
'षओी',
'षओु',
'षओू',
'षओृ',
'षओॄ',
'षओॅ',
'षओॆ',
'षओे',
'षओै',
'षओॉ',
'षओॊ',
'षओो',
'षओौ',
'षओ्',
'षओॎ',
'षओॏ',
'षओॢ',
'षओॣ',
'षऔ',
'षऔऀ',
'षऔँ',
'षऔं',
'षऔः',
'षऔऺ',
'षऔऻ',
'षऔ़',
'षऔा',
'षऔि',
'षऔी',
'षऔु',
'षऔू',
'षऔृ',
'षऔॄ',
'षऔॅ',
'षऔॆ',
'षऔे',
'षऔै',
'षऔॉ',
'षऔॊ',
'षऔो',
'षऔौ',
'षऔ्',
'षऔॎ',
'षऔॏ',
'षऔॢ',
'षऔॣ',
'सअ',
'सअऀ',
'सअँ',
'सअं',
'सअः',
'सअऺ',
'सअऻ',
'सअ़',
'सअा',
'सअि',
'सअी',
'सअु',
'सअू',
'सअृ',
'सअॄ',
'सअॅ',
'सअॆ',
'सअे',
'सअै',
'सअॉ',
'सअॊ',
'सअो',
'सअौ',
'सअ्',
'सअॎ',
'सअॏ',
'सअॢ',
'सअॣ',
'सइ',
'सइऀ',
'सइँ',
'सइं',
'सइः',
'सइऺ',
'सइऻ',
'सइ़',
'सइा',
'सइि',
'सइी',
'सइु',
'सइू',
'सइृ',
'सइॄ',
'सइॅ',
'सइॆ',
'सइे',
'सइै',
'सइॉ',
'सइॊ',
'सइो',
'सइौ',
'सइ्',
'सइॎ',
'सइॏ',
'सइॢ',
'सइॣ',
'सउ',
'सउऀ',
'सउँ',
'सउं',
'सउः',
'सउऺ',
'सउऻ',
'सउ़',
'सउा',
'सउि',
'सउी',
'सउु',
'सउू',
'सउृ',
'सउॄ',
'सउॅ',
'सउॆ',
'सउे',
'सउै',
'सउॉ',
'सउॊ',
'सउो',
'सउौ',
'सउ्',
'सउॎ',
'सउॏ',
'सउॢ',
'सउॣ',
'सऋ',
'सऋऀ',
'सऋँ',
'सऋं',
'सऋः',
'सऋऺ',
'सऋऻ',
'सऋ़',
'सऋा',
'सऋि',
'सऋी',
'सऋु',
'सऋू',
'सऋृ',
'सऋॄ',
'सऋॅ',
'सऋॆ',
'सऋे',
'सऋै',
'सऋॉ',
'सऋॊ',
'सऋो',
'सऋौ',
'सऋ्',
'सऋॎ',
'सऋॏ',
'सऋॢ',
'सऋॣ',
'सऌ',
'सऌऀ',
'सऌँ',
'सऌं',
'सऌः',
'सऌऺ',
'सऌऻ',
'सऌ़',
'सऌा',
'सऌि',
'सऌी',
'सऌु',
'सऌू',
'सऌृ',
'सऌॄ',
'सऌॅ',
'सऌॆ',
'सऌे',
'सऌै',
'सऌॉ',
'सऌॊ',
'सऌो',
'सऌौ',
'सऌ्',
'सऌॎ',
'सऌॏ',
'सऌॢ',
'सऌॣ',
'सए',
'सएऀ',
'सएँ',
'सएं',
'सएः',
'सएऺ',
'सएऻ',
'सए़',
'सएा',
'सएि',
'सएी',
'सएु',
'सएू',
'सएृ',
'सएॄ',
'सएॅ',
'सएॆ',
'सएे',
'सएै',
'सएॉ',
'सएॊ',
'सएो',
'सएौ',
'सए्',
'सएॎ',
'सएॏ',
'सएॢ',
'सएॣ',
'सऐ',
'सऐऀ',
'सऐँ',
'सऐं',
'सऐः',
'सऐऺ',
'सऐऻ',
'सऐ़',
'सऐा',
'सऐि',
'सऐी',
'सऐु',
'सऐू',
'सऐृ',
'सऐॄ',
'सऐॅ',
'सऐॆ',
'सऐे',
'सऐै',
'सऐॉ',
'सऐॊ',
'सऐो',
'सऐौ',
'सऐ्',
'सऐॎ',
'सऐॏ',
'सऐॢ',
'सऐॣ',
'सओ',
'सओऀ',
'सओँ',
'सओं',
'सओः',
'सओऺ',
'सओऻ',
'सओ़',
'सओा',
'सओि',
'सओी',
'सओु',
'सओू',
'सओृ',
'सओॄ',
'सओॅ',
'सओॆ',
'सओे',
'सओै',
'सओॉ',
'सओॊ',
'सओो',
'सओौ',
'सओ्',
'सओॎ',
'सओॏ',
'सओॢ',
'सओॣ',
'सऔ',
'सऔऀ',
'सऔँ',
'सऔं',
'सऔः',
'सऔऺ',
'सऔऻ',
'सऔ़',
'सऔा',
'सऔि',
'सऔी',
'सऔु',
'सऔू',
'सऔृ',
'सऔॄ',
'सऔॅ',
'सऔॆ',
'सऔे',
'सऔै',
'सऔॉ',
'सऔॊ',
'सऔो',
'सऔौ',
'सऔ्',
'सऔॎ',
'सऔॏ',
'सऔॢ',
'सऔॣ',
'हअ',
'हअऀ',
'हअँ',
'हअं',
'हअः',
'हअऺ',
'हअऻ',
'हअ़',
'हअा',
'हअि',
'हअी',
'हअु',
'हअू',
'हअृ',
'हअॄ',
'हअॅ',
'हअॆ',
'हअे',
'हअै',
'हअॉ',
'हअॊ',
'हअो',
'हअौ',
'हअ्',
'हअॎ',
'हअॏ',
'हअॢ',
'हअॣ',
'हइ',
'हइऀ',
'हइँ',
'हइं',
'हइः',
'हइऺ',
'हइऻ',
'हइ़',
'हइा',
'हइि',
'हइी',
'हइु',
'हइू',
'हइृ',
'हइॄ',
'हइॅ',
'हइॆ',
'हइे',
'हइै',
'हइॉ',
'हइॊ',
'हइो',
'हइौ',
'हइ्',
'हइॎ',
'हइॏ',
'हइॢ',
'हइॣ',
'हउ',
'हउऀ',
'हउँ',
'हउं',
'हउः',
'हउऺ',
'हउऻ',
'हउ़',
'हउा',
'हउि',
'हउी',
'हउु',
'हउू',
'हउृ',
'हउॄ',
'हउॅ',
'हउॆ',
'हउे',
'हउै',
'हउॉ',
'हउॊ',
'हउो',
'हउौ',
'हउ्',
'हउॎ',
'हउॏ',
'हउॢ',
'हउॣ',
'हऋ',
'हऋऀ',
'हऋँ',
'हऋं',
'हऋः',
'हऋऺ',
'हऋऻ',
'हऋ़',
'हऋा',
'हऋि',
'हऋी',
'हऋु',
'हऋू',
'हऋृ',
'हऋॄ',
'हऋॅ',
'हऋॆ',
'हऋे',
'हऋै',
'हऋॉ',
'हऋॊ',
'हऋो',
'हऋौ',
'हऋ्',
'हऋॎ',
'हऋॏ',
'हऋॢ',
'हऋॣ',
'हऌ',
'हऌऀ',
'हऌँ',
'हऌं',
'हऌः',
'हऌऺ',
'हऌऻ',
'हऌ़',
'हऌा',
'हऌि',
'हऌी',
'हऌु',
'हऌू',
'हऌृ',
'हऌॄ',
'हऌॅ',
'हऌॆ',
'हऌे',
'हऌै',
'हऌॉ',
'हऌॊ',
'हऌो',
'हऌौ',
'हऌ्',
'हऌॎ',
'हऌॏ',
'हऌॢ',
'हऌॣ',
'हए',
'हएऀ',
'हएँ',
'हएं',
'हएः',
'हएऺ',
'हएऻ',
'हए़',
'हएा',
'हएि',
'हएी',
'हएु',
'हएू',
'हएृ',
'हएॄ',
'हएॅ',
'हएॆ',
'हएे',
'हएै',
'हएॉ',
'हएॊ',
'हएो',
'हएौ',
'हए्',
'हएॎ',
'हएॏ',
'हएॢ',
'हएॣ',
'हऐ',
'हऐऀ',
'हऐँ',
'हऐं',
'हऐः',
'हऐऺ',
'हऐऻ',
'हऐ़',
'हऐा',
'हऐि',
'हऐी',
'हऐु',
'हऐू',
'हऐृ',
'हऐॄ',
'हऐॅ',
'हऐॆ',
'हऐे',
'हऐै',
'हऐॉ',
'हऐॊ',
'हऐो',
'हऐौ',
'हऐ्',
'हऐॎ',
'हऐॏ',
'हऐॢ',
'हऐॣ',
'हओ',
'हओऀ',
'हओँ',
'हओं',
'हओः',
'हओऺ',
'हओऻ',
'हओ़',
'हओा',
'हओि',
'हओी',
'हओु',
'हओू',
'हओृ',
'हओॄ',
'हओॅ',
'हओॆ',
'हओे',
'हओै',
'हओॉ',
'हओॊ',
'हओो',
'हओौ',
'हओ्',
'हओॎ',
'हओॏ',
'हओॢ',
'हओॣ',
'हऔ',
'हऔऀ',
'हऔँ',
'हऔं',
'हऔः',
'हऔऺ',
'हऔऻ',
'हऔ़',
'हऔा',
'हऔि',
'हऔी',
'हऔु',
'हऔू',
'हऔृ',
'हऔॄ',
'हऔॅ',
'हऔॆ',
'हऔे',
'हऔै',
'हऔॉ',
'हऔॊ',
'हऔो',
'हऔौ',
'हऔ्',
'हऔॎ',
'हऔॏ',
'हऔॢ',
'हऔॣ',
]

//Symbols on the main keyboard
export const KEY_SYMBOLS = [
'क',
'ख',
'ग',
'घ',
'ङ',
'च',
'छ',
'ज',
'झ',
'ञ',
'ट',
'ठ',
'ड',
'ढ',
'ण',
'त',
'थ',
'द',
'ध',
'न',
'प',
'फ',
'ब',
'भ',
'म',
'य',
'र',
'ल',
'व',
'श',
'ष',
'स',
'ह',
]

// Combining marks such as diacritics and suffixes
export const DIACRITICS = [
  '\u0900',
  '\u0901',
  '\u0902',
  '\u0903',
  '\u093A',
  '\u093B',
  '\u093C',
  '\u093E',
  '\u093F',
  '\u0940',
  '\u0941',
  '\u0942',
  '\u0943',
  '\u0944',
  '\u0945',
  '\u0946',
  '\u0947',
  '\u0948',
  '\u0949',
  '\u094A',
  '\u094B',
  '\u094C',
  '\u094D',
  '\u094E',
  '\u094F',
  '\u0962',
  '\u0963',
]
export const VOWELS = [
  'अ',
  'इ',
  'उ', 	
  'ऋ', 	
  'ऌ', 	
  'ए', 	
  'ऐ', 	
  'ओ',
  'औ',
]

if (CONFIG.normalization) {
  ORTHOGRAPHY.forEach(
    (val, i) => (ORTHOGRAPHY[i] = val.normalize(CONFIG.normalization))
  )
  KEY_SYMBOLS.forEach(
    (val, i) => (KEY_SYMBOLS[i] = val.normalize(CONFIG.normalization))
  )
  DIACRITICS.forEach(
    (val, i) => (DIACRITICS[i] = val.normalize(CONFIG.normalization))
  )
  VOWELS.forEach(
    (val, i) => (VOWELS[i] = val.normalize(CONFIG.normalization))
  )
}
